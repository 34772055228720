export interface CommonAttributes {
  id: number
  createdAt?: string | undefined | null
  updatedAt?: string | undefined | null
}

export type PageResponse<T> = {
  total: number
  results: Required<T & CommonAttributes>[]
}

export const Roles = {
  SUPERADMIN: "superadmin",
  ADMIN: "admin",
  EDITOR: "editor",
  USER: "user",
  MOBILE: "mobile",
} as const

export type RoleType = "superadmin" | "admin" | "editor" | "user" | "mobile"

export interface IFeedback {
  id?: number | undefined
  content?: string
  checked?: boolean
}

export interface IRestaurant {
  id?: number | undefined
  name: string
  email?: string
  address?: string
  city?: string
  postalCode?: string
  phoneNumber?: string
  link?: string
}

export interface IUserRestaurant {
  id?: number | undefined
  userId: number
  restaurantId: number
}

export type ProviderType = "google" | "facebook" | "apple" | undefined
export type NotificationsType = "all" | "location" | "both" | undefined

export interface IUserSettings {
  userId: number
  location?: string | undefined
  favoriteRestaurant?: string | undefined
  notifications?: NotificationsType
}

export interface IUser {
  id?: number
  username?: string | null
  email: string
  role: RoleType
  passwordHash?: string
  phoneNumber?: string | null
  restaurants?: IUserRestaurant[]
  provider?: ProviderType
  settings?: IUserSettings
  verified?: boolean
}

export interface ICouponRestaurant {
  id?: number
  couponId: number
  restaurantId: number
}

export interface ICoupon {
  id?: number | undefined
  title: string
  imageId?: number | null
  content?: string | null
  uses?: number | null
  validFrom?: string | undefined | null
  validUntil?: string | undefined | null
  publish?: string | undefined | null
  active: boolean
  userId?: number
  restaurants?: ICouponRestaurant[]
  belongsToImage?: IImage
  belongsToRestaurants?: IRestaurant[]
}

export interface ICouponEvent {
  id?: number
  couponId: number
  userId: number
}

export interface INotification {
  id?: number | undefined
  userId?: number | undefined
  title?: string | null
  content?: string | null
  deepLinkTo?: string | null // Screen name or url
  topic?: string | null // topic: PushTopic?
  publishedAt?: string | null
  sentAt?: string | null
  active: boolean
}

export interface IRestaurant {
  id?: number | undefined
  name: string
  email?: string
  address?: string
  city?: string
  postalCode?: string
  phoneNumber?: string
  link?: string
}

export enum Fit {
  contain = "contain",
  cover = "cover",
  fill = "fill",
  inside = "inside",
  outside = "outside",
}
export interface IImage {
  id?: number | undefined
  name: string
  path?: string | undefined
  fit?: Fit
}

export enum AuthError {
  InvalidUserOrPassword = "Invalid user or password",
  EmailInUse = "Email already in use",
  NotVerified = "User needs to verify",
  Error = "Something went wrong",
}

export type LoginResponse = {
  token?: string
  error?: AuthError
}

export interface QueryParams {
  page?: number
  pageSize?: number
  orderBy?: keyof INotification | keyof ICoupon | keyof CommonAttributes
  order?: "asc" | "desc" 
  search?: string
  dateLimited?: boolean
  sent?: boolean
  active?: boolean
  published?: boolean
  checked?: boolean
}

export const MobileScreens = {
  notifications: "notifications",
  coupons: "coupons",
  lunch: "lunch",
  menu: "menu",
  pass: "pass",
} as const

export interface LunchPassParams {
  qrCodeString: string
  create?: boolean
}

export interface IRegistrationParams {
  email: string
  password?: string
  role: RoleType
  provider?: ProviderType
  settings: Partial<IUserSettings>
}

export type VersionResponse = {
  version: string
}

export type ExternalLoginResponse = {
  token?: string
  email?: string
  error?: AuthError
}

export type RegistrationResponse = {
  token?: string
  status: "success" | "pending" | "error"
  error?: AuthError
}

export interface IQrCode {
  id?: number | undefined
  restaurantId?: number | undefined
  validUntil?: string | undefined | null
  qrCodeHash: string
  active: boolean
}

export interface IMagentoSearchResponse<T> {
  items: T[]
}

export interface IMagentoCategory {
  id: number
  parentId: number
  name: string
  isActive: boolean
  position: number
  level: number
  productCount: number
  childrenData: IMagentoCategory[]
}
interface IMediaGalleryEntry {
  id: number
  mediaType: string
  label: string
  position: number
  disabled: boolean
  types: string[]
  file: string
}
/**
 * Notable custom attributes:
 * - description / short description
 * - image
 * - small_image
 * - thumbnail
 */
interface ICustomAttribute {
  attributeCode: string
  value: any
}
export interface IMagentoProduct {
  id: number
  sku: string
  name: string
  attributeSetId: number
  price: number
  visibility: number // ?
  status: number
  typeId: string
  createdAt: string
  updatedAt: string
  weight: number
  mediaGalleryEntries: IMediaGalleryEntry[]
  customAttributes: ICustomAttribute[]
}

export interface ICMSBlock {
  id: number
  identifier: string
  title: string
  content: string
  active: true
}

export interface IBannerRestaurant {
  id?: number
  bannerId: number
  restaurantId: number
}

export interface IBanner {
  id?: number | undefined
  title: string
  imageId?: number | null
  publish?: string | undefined | null
  userId?: number
  deepLinkTo?: string | null // Screen name or url
  restaurants?: IBannerRestaurant[]
  belongsToImage?: IImage
  belongsToRestaurants?: IRestaurant[]
  sentAt?: string | undefined | null
}

export interface IBannerNotification {
  id?: number | undefined
  bannerId?: number | undefined
  title?: string | null
  deepLinkTo?: string | null
  publishedAt?: string | null
  sentAt?: string | null
}