import { LunchPassParams } from "../../types"
import { RestService } from "../RestService"

const URL = "/lunch-pass"

export class LunchPassApi extends RestService {
  async getOne() {
    const res = await this.httpClient.get<number>(`${URL}/`)
    if (res.ok) {
      return res.data
    } else {
      return 0
    }
  }

  async create(params: LunchPassParams) {
    const res = await this.httpClient.post(URL, params, this.headers.json)
    return res.ok
  }

  async update() {
    const res = await this.httpClient.put(URL, {}, this.headers.json)
    return res.ok
  }

  async getPreviousEntryDate() {
    const res = await this.httpClient.get<string>(`${URL}/previous-entry-date`)
    if (res.ok) return res.data
  }
}
