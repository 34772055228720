import React, { FC, ChangeEvent, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useStores } from "../models/root-store"
import { observer } from "mobx-react"
import { makeStyles } from "@material-ui/core"
import { Input, Button, ModalDialog, Text, SelectInput } from "./"
import { color } from "../theme"
import { IUser, Roles } from "../types"
import DeleteIcon from "@material-ui/icons/DeleteForever"
import IconButton from "@material-ui/core/IconButton"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles({
  formContainer: {
    "@media (min-width:600px)": {
      maxWidth: 900,
      display: "flex",
      flexFlow: "column nowrap",
      backgroundColor: color.white,
      border: ` 1px solid ${color.palette.borderGrey}`,
      padding: "0px 40px 20px 40px",
      flex: "1 1 auto",
      marginBottom: 50,
    },
    padding: "0px 10px 15px 10px",
    display: "flex",
    flexFlow: "column nowrap",
    backgroundColor: color.white,
    border: ` 1px solid ${color.palette.borderGrey}`,
    flex: "1 1 auto",
    marginBottom: 30,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  header: {
    fontSize: 28,
    fontWeight: 500,
    marginTop: 40,
  },
  inputContainer: {
    marginBottom: 30,
  },
  inputHeader: {
    fontSize: 16,
    color: color.dim,
    textIndent: 15,
    margin: "8px 0",
  },
  input: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Muli",
    minHeight: 50,
    marginBottom: 26,
    backgroundColor: color.palette.offWhite,
  },
  buttonContainer: {
    display: "flex",
    marginTop: 40,
  },
  button: {
    marginRight: 20,
  },
  cancelButton: {},
  deleteButton: {
    padding: 0,
    width: 50,
    height: 50,
    borderRadius: 50,
    marginTop: 40,
  },
  deleteIcon: {
    color: color.palette.highlightColor,
    fontSize: 28,
  },
  selectWrapper: {
    maxWidth: 500,
  },
})

interface UserFormProps {
  user: IUser | null
  className?: string
}

export const UserForm: FC<UserFormProps> = observer(({ className, user }) => {
  const { restaurantStore, userStore } = useStores()
  const { restaurantsForUser } = restaurantStore
  const { authenticatedUser, isSuperAdmin, isAtLeastAdmin } = userStore
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [selectedRestaurants, setRestaurant] = useState<string[]>([])
  const [role, setRole] = useState("")
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (user) {
      setUsername(user.username || "")
      setEmail(user.email)
      setPhoneNumber(user.phoneNumber || "")
      setRole(user.role)
      setRestaurant(user.restaurants?.map(({ restaurantId }) => restaurantId.toString()) || [])
    }
  }, [user])

  const isDisabled = () => !email.length || !role.length

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value)
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value)
  }

  const handleRestaurantChange = (event: React.ChangeEvent<HTMLInputElement | any>) => {
    setRestaurant(event.target.value)
  }

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as string)
  }

  const handleClose = () => setOpenModal(false)

  const handleCancel = () => history.push("/kayttajat")

  const openDeleteModal = () => setOpenModal(true)

  const handleSave = async () => {
    if (!isDisabled()) {
      let success = false
      if (user?.id) {
        success = await userStore.updateUser({
          id: user.id,
          username: username.length ? username : undefined,
          email: email.trim(),
          phoneNumber: phoneNumber.length ? phoneNumber : undefined,
          role,
          restaurants: selectedRestaurants.map(i => ({ restaurantId: parseInt(i, 10) })),
        } as IUser)
      } else {
        success = await userStore.addUser({
          username: username.length ? username : undefined,
          email: email.trim(),
          phoneNumber: phoneNumber.length ? phoneNumber : undefined,
          role,
          restaurants: selectedRestaurants.map(i => ({ restaurantId: parseInt(i, 10) })),
        } as IUser)
      }
      if (success) history.push("/kayttajat")
    }
  }

  const deleteUserHandler = async () => {
    if (user && user.id) {
      await userStore.deleteUser(user.id)
      history.push("/kayttajat")
    }
    handleClose()
  }

  const renderDelete = () => {
    if (authenticatedUser && authenticatedUser.role === Roles.USER) {
      return null
    }
    if (user) {
      return (
        <>
          <IconButton onClick={openDeleteModal} className={classes.deleteButton} aria-label="remove">
            <DeleteIcon classes={{ root: classes.deleteIcon }} />
          </IconButton>
        </>
      )
    }
  }

  const restaurantOptions = restaurantsForUser.map(k => ({ label: k.name, value: k.id.toString() }))

  const roles = [
    { label: "users.superadmin", value: "superadmin" },
    { label: "users.admin", value: "admin" },
    { label: "users.user", value: "user" },
  ]

  const adminRoles = [
    { label: "users.admin", value: "admin" },
    { label: "users.user", value: "user" },
  ]

  return (
    <div className={`${classes.formContainer} ${className}`}>
      <div className={classes.headerContainer}>
        <h3 className={classes.header}>{t("users.info")}</h3>
        {renderDelete()}
      </div>
      <div className={classes.inputContainer}>
        <p className={classes.inputHeader}>{t("users.name")}</p>
        <Input className={classes.input} value={username} onChange={handleNameChange} placeholder={t("users.name")} />
        <p className={classes.inputHeader}>{t("users.email")}</p>
        <Input
          className={classes.input}
          value={email}
          onChange={handleEmailChange}
          placeholder={t("users.email")}
          disabled={!!user?.id}
        />
        <p className={classes.inputHeader}>{t("users.phone")}</p>
        <Input
          className={classes.input}
          value={phoneNumber}
          onChange={handlePhoneChange}
          placeholder={t("users.phone")}
        />
        <p className={classes.inputHeader}>{t("users.restaurant")}</p>
        <SelectInput
          multiple
          className={classes.selectWrapper}
          placeholder="users.selectRestaurant"
          onChange={handleRestaurantChange}
          options={restaurantOptions}
          value={selectedRestaurants}
          disabled={!isAtLeastAdmin}
        />
        <h3 className={classes.header}>{t("users.accessRights")}</h3>
        <p className={classes.inputHeader}>{t("users.role")}</p>
        <SelectInput
          className={classes.selectWrapper}
          placeholder="users.selectRole"
          onChange={handleRoleChange}
          options={isSuperAdmin ? roles : adminRoles}
          value={role}
          disabled={!isAtLeastAdmin || authenticatedUser?.id === user?.id}
        />
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={handleSave} disabled={isDisabled()}>
            <Text preset="button" tx={user ? "common.update" : "common.save"} />
          </Button>
          <Button className={classes.cancelButton} secondary onClick={handleCancel}>
            <Text preset="button" tx="common.cancel" />
          </Button>
        </div>
      </div>
      <ModalDialog
        isOpen={openModal}
        title="users.modalTitle"
        text="users.modalText"
        onLeftClick={deleteUserHandler}
        onRightClick={handleClose}
        leftButtonText="common.remove"
        rightButtonText="common.cancel"
        deleteButton={true}
      />
    </div>
  )
})
