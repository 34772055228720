import React, { useEffect } from "react"
import { INotification } from "../../types"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { Text, RadioButtons, Button, InputDatePicker, InputTimePicker } from "../../components"
import { color } from "../../theme"
import { SEND_OPTIONS } from "."
import { useHistory } from "react-router-dom"

const useStyles = makeStyles({
  wrapper: {
    marginTop: 30,
    display: "flex",
    flexFlow: "column nowrap",
    width: "75%",
  },
  innerContainer: {
    background: color.palette.offWhite,
    border: `1px solid ${color.lighterLine}`,
    borderRadius: 5,
    paddingBottom: 20,
    paddingTop: 10,
    width: "100%",
  },
  timePickerContainer: {
    marginLeft: 48,
    width: "75%",
  },
  horizontalWrapper: {
    display: "flex",
    flexFlow: "row nowrap",
    flex: 1,
    alignItems: "center",
    margin: 5,
  },
  pickerText: {
    opacity: 0.7,
    minWidth: 40,
    fontSize: 14,
  },
  buttonContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    flex: 1,
    flexGrow: 0,
    marginTop: 40,
    justifyContent: "flex-start",
    width: "100%",
  },
  buttonSpacing: {
    marginLeft: 20,
  },
})

type NotificationSubmitProps = {
  getFieldValue: (fieldId: keyof INotification) => string | number | true | moment.Moment
  onFieldChange: (fieldId: keyof INotification | "pDate" | "pTime", val: string | moment.Moment | null) => void
  onSubmit: () => void
  fetching: boolean
  sendOption: string
  onSendOptionChange: (option: string) => void
  disabled?: boolean
  isDisabledForAdmin: boolean
}

export const NotificationSubmit: React.FC<NotificationSubmitProps> = ({
  getFieldValue,
  onFieldChange,
  onSubmit,
  fetching,
  sendOption,
  onSendOptionChange,
  disabled,
  isDisabledForAdmin,
}) => {
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    if (sendOption === SEND_OPTIONS[1].value) return onFieldChange("publishedAt", null)
    if (!getFieldValue("publishedAt")) onFieldChange("publishedAt", moment().set("hours", 0).set("minutes", 0))
  }, [sendOption])

  const handleTimeChange = (fieldName: "pDate" | "pTime", date: moment.Moment | null) => {
    onFieldChange(fieldName, date)
  }

  const handleSendRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => onSendOptionChange(e.target.value)

  const renderTimePickers = () => {
    if (sendOption !== "specifyTime") return null
    return (
      <div className={classes.timePickerContainer}>
        <div className={classes.horizontalWrapper}>
          <Text className={classes.pickerText} tx="common.dateAcronym" />
          <InputDatePicker
            value={getFieldValue("publishedAt") as moment.Moment}
            onChange={handleTimeChange}
            disabled={isDisabledForAdmin}
          />
        </div>
        <div className={classes.horizontalWrapper}>
          <Text className={classes.pickerText} tx="common.timeAcronym" />
          <InputTimePicker
            value={getFieldValue("publishedAt") as moment.Moment}
            onChange={handleTimeChange}
            disabled={isDisabledForAdmin}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      <Text preset="formHeaderSmall" tx="notification.sendMessage" />
      <Text preset="formLabel" tx="notification.sendTime" />
      <div className={classes.innerContainer}>
        <RadioButtons
          onRadioClick={handleSendRadioClick}
          buttons={SEND_OPTIONS}
          checked={sendOption}
          disabled={isDisabledForAdmin}
        />
        {renderTimePickers()}
      </div>
      <div className={classes.buttonContainer}>
        <Button onClick={onSubmit} loading={fetching} disabled={disabled || isDisabledForAdmin}>
          <Text preset="button" tx="common.save" />
        </Button>
        <Button onClick={() => history.goBack()} secondary className={classes.buttonSpacing}>
          <Text preset="button" tx="common.cancel" />
        </Button>
      </div>
    </div>
  )
}
