import { RestService } from "../RestService"
import { IRestaurant, QueryParams, PageResponse } from "../../types"

const URL = "/restaurants"

export class RestaurantApi extends RestService {
  async getOne(restaurantId: number) {
    const res = await this.httpClient.get<IRestaurant>(`${URL}/${restaurantId}`)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async getAll(params?: QueryParams) {
    const res = await this.httpClient.get<PageResponse<IRestaurant>>(URL, params)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async getRestaurantForUser() {
    const res = await this.httpClient.get<PageResponse<IRestaurant>>(`${URL}/by-user`)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async delete(id: number) {
    const url = `${URL}/${id}`
    const res = await this.httpClient.delete(url)
    return res.ok
  }
}
