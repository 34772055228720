import React, { useState, FC, useEffect } from "react"
import { IFeedback, CommonAttributes } from "../types"
import { makeStyles } from "@material-ui/styles"
import { Input, FormContainer, Text, ModalDialog, Button } from "../components"
import { color } from "../theme"
import { Switch, IconButton } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/DeleteForever"
import { useStores } from "../models/root-store"
import { useHistory } from "react-router-dom"
import moment from "moment"

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  createdWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  checkedWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 170,
    justifyContent: "space-between",
    marginTop: 20,
  },
  deleteButton: {
    padding: 0,
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  deleteIcon: {
    color: color.palette.highlightColor,
    fontSize: 28,
  },
  button: {
    display: "flex",
    alignSelf: "flex-end",
    marginTop: 20,
    float: "right",
  },
  root: {
    color: "green",
  },
})

type FeedbackFormProps = {
  feedback: (IFeedback & CommonAttributes) | null | undefined
}

export const FeedbackForm: FC<FeedbackFormProps> = props => {
  const { feedback } = props
  const { feedbackStore } = useStores()
  const history = useHistory()
  const { content, checkedWrapper, deleteButton, deleteIcon, titleWrapper, button, createdWrapper } = useStyles()
  const [openModal, setOpenModal] = useState(false)
  const [checked, setChecked] = useState(feedback?.checked)

  const updateFeedback = async () => {
    if (feedback && feedback.id && checked !== feedback?.checked) {
      await feedbackStore.updateFeedback({
        id: feedback.id,
        content: feedback.content,
        checked,
      } as IFeedback)
    }
  }

  useEffect(() => {
    updateFeedback()
  }, [checked])

  const handleChangeFeedback = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
  }

  const closeDeleteModal = () => setOpenModal(false)

  const handleDeleteFeedback = async () => {
    if (feedback && feedback.id) {
      await feedbackStore.deleteFeedback(feedback.id)
      history.push("/palautteet")
    }
    closeDeleteModal()
  }

  const openDeleteModal = () => setOpenModal(true)

  const goBack = () => history.push("/palautteet")

  const createdAt = moment(feedback?.createdAt).clone().format("DD.MM.YYYY  HH:mm")

  const renderContent = () => {
    return (
      <>
        <div className={content}>
          <div className={titleWrapper}>
            <div className={createdWrapper}>
              <Text preset="formLabel" tx="feedback.created" />
              <Text preset="defaultBig">{createdAt}</Text>
            </div>
            <IconButton onClick={openDeleteModal} className={deleteButton} aria-label="remove">
              <DeleteIcon classes={{ root: deleteIcon }} />
            </IconButton>
          </div>
          <Text preset="formLabel" tx="common.content" />
          <Input value={feedback?.content} multiline />
          <div className={checkedWrapper}>
            <Text preset="defaultBig" tx="headerTitle.checked" />
            <Switch color="primary" checked={checked} onChange={handleChangeFeedback} />
          </div>
          <Button className={button} secondary onClick={goBack}>
            <Text preset="button" tx="common.back" />
          </Button>
        </div>
        <ModalDialog
          isOpen={openModal}
          title="users.modalTitle"
          text="users.modalText"
          onLeftClick={handleDeleteFeedback}
          onRightClick={closeDeleteModal}
          leftButtonText="common.remove"
          rightButtonText="common.cancel"
          deleteButton={true}
        />
      </>
    )
  }

  return <FormContainer>{renderContent()}</FormContainer>
}
