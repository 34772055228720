import { RestService } from "../RestService"
import { IUserSettings } from "../../types"

const URL = "/user-settings"

export class UserSettingsApi extends RestService {
  async update(settings: Partial<IUserSettings>) {
    const res = await this.httpClient.put(URL, settings, this.headers.json)
    return res.ok
  }
}