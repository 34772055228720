import React, { useState, useEffect, ChangeEvent, useCallback } from "react"
import { observer } from "mobx-react"
import { Screen, Table, Tabs, TableProperty, CreateButton, Text, Loader } from "../components"
import { makeStyles } from "@material-ui/styles"
import { useStores } from "../models/root-store"
import { useHistory } from "react-router-dom"
import { SearchBar } from "../components/layout"
import { RoleType, IUser, CommonAttributes, Roles } from "../types"
import { RootPaths, SubPaths } from "../routers/Paths"
import Add from "../static/add-icon.svg"

const useStyles = makeStyles({
  container: {
    padding: "20px 50px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    msFlexDirection: "column",
  },
  tabsAndSearch: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 30,
  },
  tabs: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 30,
  },
  searchWrapper: {
    marginTop: 30,
  },
  buttonContainer: {
    alignSelf: "flex-end",
    marginTop: -40,
    marginRight: 20,
  },
  createButton: {
    width: 140,
  },
  emptyDiv: {
    height: 30,
  },
})

const properties: TableProperty<IUser>[] = [
  { key: "username", title: "username" },
  { key: "email", title: "email" },
  { key: "phoneNumber", title: "phoneNumber" },
  { key: "role", title: "role" },
]

const superAdminButtons = [
  { value: "superadmin", label: "users.superadmin" },
  { value: "admin", label: "users.admin" },
  { value: "user", label: "users.users" },
]

const adminButtons = [
  { value: "admin", label: "users.admin" },
  { value: "user", label: "users.users" },
]

const UserManagementScreen: React.ComponentType = observer(() => {
  const { userStore } = useStores()
  const { users, usersTotal, fetching, getUsers, authenticatedUser, isAtLeastAdmin } = userStore
  const { container, tabs, tabsAndSearch, buttonContainer, createButton, emptyDiv, searchWrapper } = useStyles()
  const history = useHistory()
  const [selectedRadio, setSelectedRadio] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState("")
  const pageSize = 10

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value || ""
    setSearch(searchQuery)
    setCurrentPage(0)
    getUsers(currentPage, pageSize, searchQuery, (selectedRadio as RoleType) || undefined)
  }

  const getAllUsers = async (page = 0) => {
    await getUsers(page, pageSize, search, selectedRadio as RoleType)
  }

  const memoizedGetUsers = useCallback(getAllUsers, [])

  useEffect(() => {
    memoizedGetUsers()
  }, [memoizedGetUsers])

  const toSingle = (id: number) => {
    history.push(`${RootPaths.users}${SubPaths.edit}/${id}`)
  }

  const onRadioClick = (value: string) => {
    setSelectedRadio(value)
    setCurrentPage(0)
    getUsers(0, pageSize, search, (value as RoleType) || undefined)
  }

  const onChangePage = (event: any, page: number) => {
    if (usersTotal > pageSize * page || page > -1) {
      setCurrentPage(page)
      getAllUsers(page)
    }
  }

  const renderTabsAndsearch = () => {
    if (authenticatedUser && (authenticatedUser.role === Roles.SUPERADMIN || authenticatedUser.role === Roles.ADMIN)) {
      return (
        <div className={tabsAndSearch}>
          <div className={tabs}>
            <Tabs
              onRadioClick={onRadioClick}
              buttons={authenticatedUser.role === Roles.ADMIN ? adminButtons : superAdminButtons}
              selected={selectedRadio}
              defaultChecked="super"
            />
          </div>
          <div className={searchWrapper}>
            <SearchBar width="100%" search={handleSearch} placeholder="users.search_by_name" />
          </div>
        </div>
      )
    } else {
      return <div className={emptyDiv} />
    }
  }

  const renderTable = () => {
    if (fetching) return <Loader />
    if (users.length) {
      return (
        <Table<IUser & CommonAttributes>
          total={usersTotal}
          page={currentPage}
          pageSize={pageSize}
          onChangePage={onChangePage}
          toSingle={toSingle}
          items={users}
          properties={properties}
          edit={true}
        />
      )
    }
    return null
  }

  const renderButton = () => {
    if (!isAtLeastAdmin) return null
    return (
      <div className={buttonContainer}>
        <CreateButton className={createButton} url={`${RootPaths.users}${SubPaths.create}`}>
          <img src={Add} alt="add icon" />
          <Text preset="buttonCreate" tx="Uusi" />
        </CreateButton>
      </div>
    )
  }

  return (
    <Screen header="screens.userManagement">
      <div className={container}>
        {renderTabsAndsearch()}
        {renderTable()}
        {renderButton()}
      </div>
    </Screen>
  )
})

export default UserManagementScreen
