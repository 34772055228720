import React, { useState } from "react"
import { Table as MUITable, TableBody, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import TableHeader from "./TableHeader"
import TableRow from "./TableRow"
import TableFooter from "./TableFooter"
import { color } from "../../theme"
import { useTranslation } from "react-i18next"
import { CommonAttributes } from "../../types"

const useStyles = makeStyles({
  tableContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  root: {
    alignSelf: "center",
    backgroundColor: color.palette.white,
    maxWidth: "100%",
    overflowX: "auto",
    overflow: "visible",
    width: "100%",
    borderRadius: 5,
    border: `1px solid ${color.line}`,
  },
  table: {
    overflowX: "auto",
    width: "100%",
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    color: color.dim,
  },
  placeholderContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  placeholderText: {
    fontSize: 30,
    color: color.dim,
    textIndent: 50,
  },
  placeholderTextSub: {
    fontSize: 18,
    color: color.dim,
    textIndent: 50,
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  emptyContainer: {
    width: 40,
    height: 70,
  },
})

export type TableProperty<T> = {
  key: keyof T
  title: string
}

type TableProps<T> = {
  loading?: boolean
  total: number
  page?: number
  pageSize?: number
  onChangePage?(event: any, toPage: number): void
  toSingle?(id: number): void
  items: T[]
  properties: TableProperty<T>[]
  hideCreateNew?: boolean
  edit?: boolean
}

/**
 * A somewhat reusable Table component.
 * Make sure keys of the objects passed as items that you want to display
 * match the provided headerTitles
 */
export function Table<T>(props: TableProps<T & CommonAttributes>) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { loading, total, page = 1, pageSize = 10, onChangePage, toSingle, items, properties, edit } = props

  const [currentPage, setCurrentPage] = useState(page)
  const [currentPageSize] = useState(pageSize)

  const handlePageChange = (event: any, toPage: number) => {
    setCurrentPage(toPage)
    if (onChangePage) onChangePage(event, toPage)
  }

  const renderLoading = () => {
    if (loading) return <CircularProgress className={classes.loading} size={30} />
    return null
  }

  const renderRows = () => {
    if (items && items.length) {
      return items.map(item => (
        <TableRow key={item.id} item={item} toSingle={toSingle} properties={properties} edit={edit} />
      ))
    }
    return null
  }

  const renderFooter = () => {
    if (total < pageSize) return <div className={classes.emptyContainer} />
    return <TableFooter page={currentPage} pageSize={currentPageSize} onChangePage={handlePageChange} total={total} />
  }

  const renderContent = () => {
    if (!total || !items.length) {
      return (
        <div className={classes.placeholderContainer}>
          <span className={classes.placeholderText}>{t("common.empty")}</span>
          <span className={classes.placeholderTextSub}>{t("common.emptySub")}</span>
        </div>
      )
    }
    return (
      <div className={classes.tableContainer}>
        <div className={classes.root}>
          <MUITable className={classes.table} padding="none">
            <TableHeader properties={properties} edit={edit} />
            <TableBody>{renderRows()}</TableBody>
          </MUITable>
          {renderLoading()}
        </div>
        <div className={classes.footerContainer}>{renderFooter()}</div>
      </div>
    )
  }

  return renderContent()
}
