import { RestService } from "../RestService"
import {
  LoginResponse,
  IRegistrationParams,
  ExternalLoginResponse,
  ProviderType,
  RoleType,
  AuthError,
  RegistrationResponse,
} from "../../types"

const URL = "/auth"

export class AuthApi extends RestService {
  async login(
    email: string,
    password: string,
    validRoles?: RoleType[],
    callback?: (token: string) => Promise<boolean>
  ): Promise<LoginResponse | undefined> {
    const response = await this.httpClient.post<LoginResponse>(`${URL}/login`, {
      email,
      password,
      validRoles,
    })
    if (response.ok) {
      const token = response.data?.token
      if (callback && token) await callback(token)
    }
    return response.data
  }

  async register(
    registeringUser: IRegistrationParams,
    callback?: (token: string) => Promise<boolean>
  ) {
    const response = await this.httpClient.post<RegistrationResponse>(
      `${URL}/register`,
      registeringUser
    )
    if (response.ok) {
      const token = response.data?.token
      if (callback && token) await callback(token)
    }
    return response.data
  }

  async resetPassword(email: string, admin?: boolean) {
    return await this.httpClient.post<LoginResponse>(`${URL}/reset-password`, {
      email,
      admin,
    })
  }

  async setPassword(code: string, email: string, password: string) {
    return await this.httpClient.post<LoginResponse>(`${URL}/set-password`, {
      code,
      email,
      password,
    })
  }

  async changePassword(password: string, newPassword: string) {
    const res = await this.httpClient.post<LoginResponse>(`${URL}/change-password`, {
      password,
      newPassword,
    })
    if (res.ok) {
      return true
    } else {
      return false
    }
  }

  async externalLogin(
    accessToken: string,
    provider: ProviderType,
    callback?: (token: string) => Promise<boolean>
  ) {
    const response = await this.httpClient.post<ExternalLoginResponse>(`${URL}/external-login`, {
      accessToken,
      provider,
    })
    if (response.ok && response.data) {
      if (response.data.email) return response.data.email
      const token = response.data.token
      if (!token) return false
      if (callback) return await callback(token)
      return true
    }
    return false
  }

  async verifyCode(code: string, email: string, callback?: (token: string) => Promise<boolean>) {
    const response = await this.httpClient.get<LoginResponse>(`${URL}/verification`, {
      code,
      email,
    })
    if (response.ok) {
      const token = response.data?.token
      if (!token) return false
      if (callback) return await callback(token)
      return true
    }
    return false
  }

  async resendCode(email: string) {
    const response = await this.httpClient.get<RegistrationResponse>(`${URL}/resend-code`, {
      email,
    })
    if (response.data?.error === AuthError.NotVerified) {
      return true
    }
    return false
  }
}
