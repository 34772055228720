import React, { useState, useEffect, ChangeEvent, useCallback } from "react"
import { observer } from "mobx-react"
import { Screen, Table, TableProperty, CreateButton, Text, Loader } from "../components"
import { makeStyles } from "@material-ui/styles"
import { useStores } from "../models/root-store"
import { useHistory } from "react-router-dom"
import { SearchBar } from "../components/layout"
import { CommonAttributes, INotification } from "../types"
import { RootPaths, SubPaths } from "../routers/Paths"
import Add from "../static/add-icon.svg"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  container: {
    padding: "20px 50px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    msFlexDirection: "column",
  },
  searchWrapper: {
    margin: "30px 0 30px",
    maxWidth: "25%",
  },
  buttonContainer: {
    alignSelf: "flex-end",
    marginTop: -40,
    marginRight: 20,
  },
  createButton: {
    width: 140,
  },
  emptyDiv: {
    height: 30,
  },
})

export const STATUS = { SENT: "status.sent", WAITING: "status.waiting", UNFINISHED: "status.unfinished" } as const

type NotifactionTableType = CommonAttributes & INotification & { status?: string }

const properties: TableProperty<NotifactionTableType>[] = [
  { key: "title", title: "title" },
  { key: "topic", title: "topic" },
  { key: "status", title: "status" },
]

export const NotificationsScreen: React.FC = observer(() => {
  const {
    notificationStore: { notifications, total, fetching, getNotifications },
    userStore: { authenticatedUser, isAtLeastAdmin },
  } = useStores()
  const { container, buttonContainer, createButton, searchWrapper } = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState("")
  const pageSize = 10

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value || ""
    setSearch(searchQuery)
    setCurrentPage(0)
    getNotifications(currentPage, pageSize, searchQuery)
  }

  const getAllNotifications = async (page = 0) => {
    await getNotifications(page, pageSize, search)
  }

  const memoizedGetNotifications = useCallback(getAllNotifications, [])

  useEffect(() => {
    memoizedGetNotifications()
  }, [memoizedGetNotifications])

  const toSingle = (id: number) => {
    history.push(`${RootPaths.notifications}${SubPaths.edit}/${id}`)
  }

  const onChangePage = (event: any, page: number) => {
    if (total > pageSize * page || page > -1) {
      setCurrentPage(page)
      getAllNotifications(page)
    }
  }

  const notificationsWithStatus = notifications.map(n => {
    const copy = { ...n }
    copy.topic = n.topic && n.topic.split(";").join(", ")
    if (n.topic === "all") copy.topic = t("notification.all")
    if (!n.publishedAt && !n.sentAt) return { ...copy, status: t(STATUS.UNFINISHED) }
    if (n.publishedAt && !n.sentAt) return { ...copy, status: t(STATUS.WAITING) }
    if (n.publishedAt && n.sentAt) return { ...copy, status: t(STATUS.SENT) }
    return n
  })

  const renderSearch = () => (
    <div className={searchWrapper}>
      <SearchBar width="100%" search={handleSearch} placeholder="notification.searchByTitle" />
    </div>
  )

  const renderTable = () => {
    if (notifications && notifications.length) {
      return (
        <Table<NotifactionTableType>
          total={total}
          page={currentPage}
          pageSize={pageSize}
          onChangePage={onChangePage}
          toSingle={toSingle}
          items={notificationsWithStatus}
          properties={properties}
          edit={true}
        />
      )
    } else if (fetching) {
      return <Loader />
    }
    return null
  }

  const renderButton = () => {
    if (authenticatedUser && isAtLeastAdmin) {
      return (
        <div className={buttonContainer}>
          <CreateButton className={createButton} url={`${RootPaths.notifications}${SubPaths.create}`}>
            <img src={Add} alt="add icon" />
            <Text preset="buttonCreate" tx="Uusi" />
          </CreateButton>
        </div>
      )
    }
    return null
  }

  return (
    <Screen header="screens.notifications">
      <div className={container}>
        {renderSearch()}
        {renderTable()}
        {renderButton()}
      </div>
    </Screen>
  )
})
