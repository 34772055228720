import React, { useEffect, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react"
import { Screen, Button, ModalDialog, Text, Loader, SelectInput } from "../components"
import { makeStyles } from "@material-ui/styles"
import QRCodeCreator from "../components/qrCode/QRCodeCreator"
import QrCodeInfo from "../components/qrCode/QrCodeInfo"
import { color } from "../theme"
import { useStores } from "../models/root-store"
import { IRestaurant } from "../types"
import moment from "moment"

const useStyles = makeStyles({
  contentContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: "15px 10px",
    flexDirection: "row",
    "@media (min-width:600px)": {
      display: "flex",
      flexWrap: "wrap",
      padding: "50px",
      flexDirection: "row",
    },
  },
  qrAndFormContainer: {
    backgroundColor: color.palette.white,
    display: "flex",
    flexDirection: "column",
    maxWidth: 730,
    flexWrap: "wrap",
    padding: "0 10px 10px",
    border: ` 1px solid ${color.palette.borderGrey}`,
    "@media (min-width:600px)": {
      backgroundColor: color.palette.white,
      display: "flex",
      flexDirection: "column",
      maxWidth: 730,
      flexWrap: "wrap",
      padding: "0 40px 40px",
      border: ` 1px solid ${color.palette.borderGrey}`,
    },
  },
  downloadContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 50,
    marginTop: 50,
  },
  button: {
    marginTop: 50,
    display: "flex",
    flexDirection: "row",
    float: "right",
    marginRight: 20,
  },
  downloadTitle: {
    fontFamily: "Muli",
    fontSize: 16,
    marginTop: 0,
    color: color.dim,
    marginBottom: 0,
  },
  listText: {
    fontFamily: "Muli",
    fontSize: 20,
    fontWeight: 300,
  },
  createdContainer: {
    marginTop: 50,
  },
  selectWrapper: {
    marginTop: 50,
  },
  notActiveText: {
    fontSize: 20,
    fontWeight: 500,
    marginTop: 20,
    fontFamily: "Muli",
    color: color.palette.highlightColor,
  },
  notActiveContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 280,
    height: 280,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  qrContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: 30,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  list: {
    listStyle: "none",
    padding: 0,
  },
  listContent: {
    marginBottom: 10,
  },
  fetchingContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 150,
    display: "flex",
  },
})
const QRCodeScreen: React.ComponentType = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    qrCodeStore: { getQrCode, createQrCode, createNewDownloadEvent, qrCode, events, fetching },
    restaurantStore: { restaurantsForUser, getRestaurantsForUser },
  } = useStores()
  const [openModal, setOpenModal] = useState(false)
  const [selectedRestaurant, setRestaurant] = useState<IRestaurant>()
  const { qrCodeHash, active, username, createdAt: createdAtDate } = qrCode
  const createdAt = moment(createdAtDate).clone().format("DD.MM.YYYY  HH:mm")

  const fetchQrCode = async (id: number) => {
    await getQrCode(id)
    // const qrCode: IQrCode = await getQrCode(id)
    // if (qrCode) {
    // }
  }
  const memoizedfetchQrCode = useCallback(fetchQrCode, [])

  useEffect(() => {
    if (selectedRestaurant?.id) {
      memoizedfetchQrCode(selectedRestaurant.id)
    }
  }, [selectedRestaurant, memoizedfetchQrCode])

  useEffect(() => {
    const getRestaurantsAndSetInitialSelected = async () => {
      await getRestaurantsForUser()
      setRestaurant(restaurantsForUser[0])
    }

    getRestaurantsAndSetInitialSelected()
  }, [getRestaurantsForUser, restaurantsForUser])

  const handleRestaurantChange = (event: React.ChangeEvent<HTMLInputElement | any>) => {
    if (event.target.value) {
      const restaurant = restaurantsForUser.find(z => z.id === Number(event.target.value))
      setRestaurant(restaurant)
    } else {
      setRestaurant(undefined)
    }
  }

  const restaurantOptions = restaurantsForUser.map(k => {
    return { label: k.name, value: k.id.toString() }
  })

  const handleClose = () => {
    setOpenModal(false)
  }

  const createNewQrCode = () => {
    if (selectedRestaurant?.id) {
      createQrCode(selectedRestaurant.id)
    }
    handleClose()
  }

  const openConfirmModal = () => setOpenModal(true)

  const downloadQR = () => {
    const canvas = document.getElementById("qr-code-canvas") as HTMLCanvasElement | null
    if (!canvas) {
      return null
    }
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
    const downloadLink = document.createElement("a")
    downloadLink.href = pngUrl
    downloadLink.download = `${selectedRestaurant?.name}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    if (fetching) return <Loader />
    if (qrCode?.qrCodeId) {
      createNewDownloadEvent(qrCode.qrCodeId)
    }
  }

  const renderQrCode = () => {
    if (active && qrCodeHash) {
      const code = JSON.stringify({ id: qrCode.qrCodeId, code: qrCodeHash })
      return <QRCodeCreator code={code} />
    }
    return (
      <div className={classes.notActiveContainer}>
        <span className={classes.notActiveText}>{t("qrcode.notInUse")}</span>
      </div>
    )
  }

  const renderButtons = () => {
    return (
      <div className={classes.buttonsContainer}>
        <Button className={classes.button} onClick={openConfirmModal} secondary>
          <Text preset="button" tx="qrcode.createNew" />
        </Button>
        <Button className={classes.button} onClick={() => downloadQR()}>
          <Text preset="button" tx="qrcode.download" />
        </Button>
      </div>
    )
  }

  const renderDownloadList = () => {
    if (fetching) return <Loader />
    if (events?.length > 0) {
      return (
        <>
          <div className={classes.downloadContainer}>
            <p className={classes.downloadTitle}>{t("qrcode.downloads")}</p>
            <ul className={classes.list}>
              {events &&
                events.map(({ username, createdAt: eventCreatedAt }) => {
                  const createdAtDate = moment(eventCreatedAt).format("DD.MM.YYYY")
                  const createdAtTime = moment(eventCreatedAt).format("HH:mm")
                  return (
                    <li key={createdAt} className={classes.listContent}>
                      <span className={classes.listText}>
                        {createdAtDate} {t("qrcode.time")}
                        {createdAtTime} {username}
                      </span>
                    </li>
                  )
                })}
            </ul>
          </div>
        </>
      )
    }
    return null
  }

  const renderContent = () => {
    return (
      <div className={classes.contentContainer}>
        <div className={classes.qrAndFormContainer}>
          <SelectInput
            className={classes.selectWrapper}
            placeholder={"users.selectRestaurant"}
            onChange={handleRestaurantChange}
            options={restaurantOptions}
            value={selectedRestaurant?.id || ""}
          />
          <div className={classes.formContainer}>
            <div className={classes.qrContainer}>
              {renderQrCode()}
              {qrCode.qrCodeId ? (
                <QrCodeInfo validUntil={qrCode?.validUntil} createdAt={createdAt} creator={username} />
              ) : null}
            </div>
            {renderButtons()}
          </div>
        </div>
        {renderDownloadList()}
      </div>
    )
  }

  return (
    <Screen header="screens.QRcode">
      {renderContent()}
      <ModalDialog
        isOpen={openModal}
        title="qrcode.newQrCode"
        text="qrcode.modalText"
        onLeftClick={handleClose}
        onRightClick={() => createNewQrCode()}
        leftButtonText="common.cancel"
        rightButtonText="common.accept"
      />
    </Screen>
  )
})

export default QRCodeScreen
