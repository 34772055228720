import { RestService } from "../RestService"
import { Fit } from "../../types"

const URL = "/images"
export class ImageApi extends RestService {
  async createImage(image: File, fit: Fit) {
    const formData = new FormData()
    formData.append("fit", fit)
    formData.append("file", image)

    const res = await this.httpClient.post(URL, formData, this.headers.multipart)
    return res
  }
  async delete(id: number) {
    const url = `${URL}/${id}`
    const res = await this.httpClient.delete(url)
    return res.ok
  }
}
