import { ApisauceInstance } from "apisauce"
import { QueryParams, PageResponse } from "../types"

export abstract class RestService {
  constructor(protected httpClient: ApisauceInstance) {}
  headers = {
    json: { headers: { "content-type": "application/json" } } as const,
    multipart: { headers: { "content-type": "multipart/form-data" } } as const,
    tsv: { headers: { "content-type": "text/tab-separated-values" } } as const,
  }
  getAll?(params?: QueryParams, [x]?: any): Promise<PageResponse<any> | any[] | undefined | null>
  getOne?(id: number, [x]?: any): Promise<any | undefined | null> | undefined
  create?(item: any): Promise<boolean | Object>
  update?(item: any): Promise<boolean>
  delete?(id: number): Promise<boolean>
}
