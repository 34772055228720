import React, { useState, useEffect, useCallback } from "react"
import { observer } from "mobx-react"
import { Screen, UserForm, Loader } from "../components"
import { makeStyles } from "@material-ui/core"
import { color } from "../theme"
import { useParams } from "react-router-dom"
import { useStores } from "../models/root-store"
import { IUser, CommonAttributes } from "../types"
import { useTranslation } from "react-i18next"
import moment from "moment"

const useStyles = makeStyles({
  container: {
    padding: 10,
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    "@media (min-width:600px)": {
      padding: 50,
      display: "flex",
      flex: 1,
    },
  },
  modified: {
    fontFamily: "Muli",
    fontSize: 16,
    marginTop: 0,
    color: color.dim,
    marginBottom: 10,
  },
  date: {
    fontFamily: "Muli",
    fontSize: 20,
    fontWeight: 300,
    marginBottom: 10,
  },
  createdContainer: {
    marginTop: 40,
  },
  modifiedContainer: {
    marginLeft: 40,
  },
})

interface UserScreenProps {}

const UserScreen: React.ComponentType<UserScreenProps> = observer(() => {
  const { t } = useTranslation()
  const { userStore } = useStores()
  const { fetching } = userStore
  const [user, setUser] = useState<(IUser & CommonAttributes) | null>(null)
  const { modifiedContainer, modified, date, createdContainer, container } = useStyles()
  const { id } = useParams<{ id: string }>()

  const getUser = async (id: number) => {
    const newUser = await userStore.getUser(id)
    if (newUser) {
      setUser(newUser)
    }
  }
  const memoizedGetUser = useCallback(getUser, [])

  useEffect(() => {
    if (id) {
      memoizedGetUser(Number(id))
    }
  }, [id, memoizedGetUser])

  //const separator = id ? classes.separator : undefined
  const updatedDate = moment(user?.updatedAt).format("DD.MM.YYYY")
  const updatedTime = moment(user?.updatedAt).format("HH.mm")
  const createdDate = moment(user?.createdAt).format("DD.MM.YYYY")
  const createdTime = moment(user?.createdAt).format("HH.mm")

  const header = id ? "Muokkaa käyttäjätietoja" : "Lisää käyttäjä"

  const renderModified = () => {
    if (!id) return null
    if (updatedDate && createdDate) {
      return (
        <div className={modifiedContainer}>
          <div>
            <p className={modified}>{t("users.modified")}</p>
            <span className={date}>{updatedDate}</span>
            <span className={date}>{` klo ${updatedTime}`}</span>
          </div>
          <div className={createdContainer}>
            <p className={modified}>{t("users.created")}</p>
            <span className={date}>{createdDate}</span>
            <span className={date}>{` klo ${createdTime}`}</span>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const renderContent = () => {
    if (fetching) return <Loader />
    return (
      <div className={container}>
        <UserForm user={user} />
        {renderModified()}
      </div>
    )
  }
  return <Screen header={header}>{renderContent()}</Screen>
})

export default UserScreen
