import { ICMSBlock, IMagentoCategory, IMagentoProduct } from "../../types"
import { RestService } from "../RestService"

const URL = "/magento"

export class MagentoApi extends RestService {
  async getCategories(type: "productCategories" | "restaurants") {
    const res = await this.httpClient.get<IMagentoCategory[]>(`${URL}/categories/${type}`)
    if (res.ok) return res.data
  }
  async getProductsByCategory(categoryId: number) {
    const res = await this.httpClient.get<IMagentoProduct[]>(
      `${URL}/category-products/${categoryId}`
    )
    if (res.ok) return res.data
  }
  async getProductBySKU(SKU: string) {
    const res = await this.httpClient.get<IMagentoProduct>(`${URL}/products/${SKU}`)
    if (res.ok) return res.data
  }
  async getRestaurants() {
    const res = await this.httpClient.get<IMagentoCategory[]>(`${URL}/restaurants`)
    if (res.ok) return res.data
  }
  async getLunchMenuCmsBlock(restaurant: string) {
    const res = await this.httpClient.get<ICMSBlock[]>(`${URL}/lunchMenu/${restaurant}`)
    if (res.ok) return res.data
  }
}
