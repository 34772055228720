import React, { useState, useEffect } from "react"
import { INotification, MobileScreens, Roles } from "../../types"
import { observer } from "mobx-react"
import { makeStyles } from "@material-ui/styles"
import { IconButton, InputAdornment } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/DeleteForever"
import { Input, Text, RadioButtons, SelectInput, ModalDialog } from "../../components"
import { color } from "../../theme"
import { useStores } from "../../models/root-store"
import { NotificationStateType } from "./NotificationScreen"

const DEEPLINK_OPTIONS = [
  { label: "notification.nowhere", value: "nowhere" },
  { label: "notification.screen", value: "screen" },
  { label: "notification.external", value: "external" },
]
const TARGET_OPTIONS = [
  { label: "notification.nationwide", value: "nationwide" },
  { label: "notification.local", value: "local" },
]
const LOCAL_TARGET_OPTIONS = [
  { label: "notification.city", value: "city" },
  { label: "notification.restaurant", value: "restaurant" },
]

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "75%",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    marginTop: 20,
  },
  inputWrapper: {
    marginLeft: 15,
    marginTop: 20,
    width: "50%",
  },
  selectWrapper: {
    marginLeft: 15,
    marginTop: 20,
    width: "50%",
  },
  adornment: {
    height: "calc(100% + 2px)",
    color: color.palette.grey9,
    backgroundColor: color.palette.grey6,
    justifyContent: "center",
    borderRadius: 3,
    border: `1px solid ${color.line}`,
    padding: "0px 15px",
    marginLeft: -1,
    maxHeight: "none",
    fontFamily: "Muli",
    fontStyle: "italic",
  },
  innerSelect: {
    marginLeft: 30,
    marginTop: 10,
    width: "calc(100% - 30px - 15px)",
  },
  deleteButton: {
    padding: 0,
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  deleteIcon: {
    color: color.palette.highlightColor,
    fontSize: 28,
  },
})

const MobileScreenOptions = Object.keys(MobileScreens).map(k => ({ label: `notification.${k}`, value: k }))

type NotificationFormProps = {
  getFieldValue: (fieldId: keyof INotification) => string | number | true
  onFieldChange: (fieldId: keyof INotification, val: string | string[]) => void
  state: NotificationStateType
  isDisabledForAdmin: boolean
}

export const NotificationForm: React.FC<NotificationFormProps> = observer(
  ({ getFieldValue, onFieldChange, state, isDisabledForAdmin }) => {
    const {
      content,
      headerContainer,
      title,
      inputWrapper,
      selectWrapper,
      adornment,
      innerSelect,
      deleteButton,
      deleteIcon,
    } = useStyles()
    const {
      restaurantStore: {
        getRestaurants,
        asDropdownList,
        citiesAsDropdownList,
        fetching,
        restaurants,
        allAsDropdownList,
      },
      userStore: { authenticatedUser, isAtLeastAdmin, isAdmin },
      notificationStore,
    } = useStores()
    const [modalState, setModalState] = useState(false)
    const [deeplinkOption, setDeeplinkOption] = useState<string>(DEEPLINK_OPTIONS[0].value)
    const [targetOption, setTargetOption] = useState<string>(TARGET_OPTIONS[0].value)
    const [localTargetOption, setLocalTargetOption] = useState<string>(LOCAL_TARGET_OPTIONS[0].value)
    const [initiated, setInitiated] = useState<boolean>(false)

    useEffect(() => {
      if (targetOption === TARGET_OPTIONS[0].value) return onFieldChange("topic", [])
      if (targetOption === TARGET_OPTIONS[1].value) getRestaurants(0, 100)
    }, [targetOption])

    // Selects the aproppriate options to match state changes
    useEffect(() => {
      if (!initiated && state.id) {
        if (restaurants.filter(r => state.topic?.includes(r.name)).length) {
          setTargetOption(TARGET_OPTIONS[1].value) // value: "local"
          setLocalTargetOption(LOCAL_TARGET_OPTIONS[1].value) // value: "restaurant"
        } else if (restaurants.filter(r => state.topic?.includes(r.city)).length) {
          setTargetOption(TARGET_OPTIONS[1].value) // value: "local"
          setLocalTargetOption(LOCAL_TARGET_OPTIONS[0].value) // value: "city"
        } else {
          setTargetOption(TARGET_OPTIONS[0].value) // value: "all"
          setLocalTargetOption(LOCAL_TARGET_OPTIONS[0].value) // value: "city"
        }
        if (state.deepLinkTo) {
          if (Object.keys(MobileScreens).indexOf(state.deepLinkTo) !== -1) {
            setDeeplinkOption(DEEPLINK_OPTIONS[1].value) // screen
          } else {
            setDeeplinkOption(DEEPLINK_OPTIONS[2].value) // external url
          }
        }
        setInitiated(true)
      } else if (isAdmin && !state.id) {
        // Admin level users are only able to send notifications locally to their affiliated restaurant(s)
        setTargetOption(TARGET_OPTIONS[1].value) // value: "local"
        setLocalTargetOption(LOCAL_TARGET_OPTIONS[1].value) // value: "restaurant"
      }
    }, [state])

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => onFieldChange("title", e.target.value)
    const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) => onFieldChange("content", e.target.value)
    const handleDlTargetChange = (e: React.ChangeEvent<HTMLInputElement | any>) => {
      onFieldChange("deepLinkTo", e.target.value)
    }
    const handleTopicChange = (e: React.ChangeEvent<HTMLInputElement | any>) => {
      onFieldChange("topic", e.target.value)
    }

    const handleDeeplinkRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
      onFieldChange("deepLinkTo", "")
      setDeeplinkOption(e.target.value)
    }
    const handleTargetRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === TARGET_OPTIONS[0].value) onFieldChange("topic", [])
      setTargetOption(e.target.value)
    }
    const handleLocalTargetRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
      onFieldChange("topic", [])
      setLocalTargetOption(e.target.value)
    }

    const handleDeleteNotification = async () => {
      if (state?.id) {
        await notificationStore.deleteNotification(state.id)
        window.history.back()
      }
      setModalState(false)
    }

    const disabledRadioButtons = authenticatedUser?.role !== Roles.SUPERADMIN

    const renderDelete = () => {
      if (!state?.id || !isAtLeastAdmin || isDisabledForAdmin) {
        return null
      } else if (state) {
        return (
          <IconButton onClick={() => setModalState(true)} className={deleteButton} aria-label="remove">
            <DeleteIcon classes={{ root: deleteIcon }} />
          </IconButton>
        )
      }
    }

    const renderExternalAdornment = () => (
      <InputAdornment className={adornment} position="start">
        {"https://"}
      </InputAdornment>
    )

    const renderDeeplinkSelection = () => {
      if (deeplinkOption === "screen") {
        return (
          <SelectInput
            className={selectWrapper}
            placeholder="notification.selectScreen"
            onChange={handleDlTargetChange}
            options={MobileScreenOptions}
            value={getFieldValue("deepLinkTo")}
            disabled={isDisabledForAdmin}
          />
        )
      }
      if (deeplinkOption === "external") {
        return (
          <Input
            className={inputWrapper}
            value={getFieldValue("deepLinkTo")}
            onChange={handleDlTargetChange}
            InputProps={{ startAdornment: renderExternalAdornment() }}
            disabled={isDisabledForAdmin}
          />
        )
      }
      return null
    }

    const renderTopicDropDown = () => {
      if (targetOption === "all") return null
      if (localTargetOption === "city") {
        return (
          <SelectInput
            className={selectWrapper}
            placeholder="notification.selectCity"
            onChange={handleTopicChange}
            options={citiesAsDropdownList()}
            value={getFieldValue("topic")}
            loading={fetching}
            multiple
            disabled={isDisabledForAdmin}
          />
        )
      }
      if (localTargetOption === "restaurant" || isAdmin) {
        return (
          <SelectInput
            className={selectWrapper}
            placeholder="notification.selectRestaurant"
            onChange={handleTopicChange}
            options={isDisabledForAdmin ? allAsDropdownList() : asDropdownList()}
            value={getFieldValue("topic")}
            loading={fetching}
            multiple
            disabled={isDisabledForAdmin}
          />
        )
      }
    }

    const renderTopicSelection = () => {
      if (targetOption === "local") {
        return (
          <div className={innerSelect}>
            {authenticatedUser?.role === Roles.SUPERADMIN && (
              <RadioButtons
                onRadioClick={handleLocalTargetRadioClick}
                buttons={LOCAL_TARGET_OPTIONS}
                checked={localTargetOption}
                disabled={disabledRadioButtons || isDisabledForAdmin}
              />
            )}
            {renderTopicDropDown()}
          </div>
        )
      }
      return null
    }

    return (
      <>
        <div className={content}>
          <div className={headerContainer}>
            <Text
              preset="formHeader"
              tx={
                getFieldValue("id")
                  ? isDisabledForAdmin
                    ? "common.view"
                    : "notification.edit"
                  : "notification.notificationContent"
              }
            />
            {renderDelete()}
          </div>
          <Text preset="formLabel" tx="common.title" className={title} />
          <Input value={getFieldValue("title")} onChange={handleTitleChange} disabled={isDisabledForAdmin} />
          <Text preset="formLabel" tx="notification.textContent" />
          <Input
            multiline
            value={getFieldValue("content")}
            onChange={handleContentChange}
            disabled={isDisabledForAdmin}
          />
          <Text preset="formLabelBold" tx="notification.shouldDeeplink" />
          <RadioButtons
            onRadioClick={handleDeeplinkRadioClick}
            buttons={DEEPLINK_OPTIONS}
            checked={deeplinkOption}
            disabled={isDisabledForAdmin}
          />
          {renderDeeplinkSelection()}
          <Text preset="formLabelBold" tx="notification.whatIsTarget" />
          <RadioButtons
            onRadioClick={handleTargetRadioClick}
            buttons={TARGET_OPTIONS}
            checked={targetOption}
            disabled={disabledRadioButtons || isDisabledForAdmin}
          />
          {renderTopicSelection()}
          <ModalDialog
            isOpen={modalState}
            title="notification.delete"
            onLeftClick={handleDeleteNotification}
            onRightClick={() => setModalState(false)}
            leftButtonText="common.remove"
            rightButtonText="common.cancel"
            deleteButton={true}
          />
        </div>
      </>
    )
  }
)
