import { RestService } from "../RestService"
import { IBanner, QueryParams, PageResponse } from "../../types"

const URL = "/banners"

export class BannerApi extends RestService {
  async getAll(params: QueryParams) {
    const res = await this.httpClient.get<PageResponse<IBanner>>(URL, params)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async getAllMobile(params: QueryParams) {
    const res = await this.httpClient.get<PageResponse<IBanner>>("/mobile-banners", params)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async getOne(bannerId: number) {
    const res = await this.httpClient.get<IBanner>(`${URL}/${bannerId}`)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async create(banner: IBanner) {
    const res = await this.httpClient.post(URL, banner, this.headers.json)
    return res.ok
  }

  async update(banner: IBanner) {
    const res = await this.httpClient.put(URL, banner, this.headers.json)
    return res.ok
  }

  async delete(id: number) {
    const url = `${URL}/${id}`
    const res = await this.httpClient.delete(url)
    return res.ok
  }
}