import { RootStore } from "./../root-store/root-store"
import { api } from "./../../services/api"
import { Instance, SnapshotOut, types, flow, getParent } from "mobx-state-tree"
import { FlowType } from "../../rest-api/RestApi"
import { IImage, Fit } from "../../types"

const ImageModel = types.model("ImageModel", {
  id: types.identifierNumber,
  name: types.string,
  couponId: types.maybeNull(types.number),
  path: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

type ImageModel = Instance<typeof ImageModel>

export const ImageStoreModel = types
  .model("ImageStore")
  .props({
    image: types.maybe(types.model("Image")),
    images: types.optional(types.array(ImageModel), []),
  })
  .actions(self => ({
    createImage: flow(function* (image: File): FlowType {
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.images.createImage(image, Fit.cover)
      if (result.ok) {
        messageStore.setMessage("ad.imageAdded", "success")
        return result.data as IImage
      }
      messageStore.setMessage("ad.error", "error")
      return null
    }),
    deleteImage: flow(function* (imageId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.images.delete(imageId)
      if (result) {
        messageStore.setMessage("common.deleted", "success")
        return true
      }
      messageStore.setMessage("ad.error", "error")
      return null
    }),
  }))

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ImageStoreType = Instance<typeof ImageStoreModel>
export interface ImageStore extends ImageStoreType {}
type ImageStoreSnapshotType = SnapshotOut<typeof ImageStoreModel>
export interface ImageStoreSnapshot extends ImageStoreSnapshotType {}
