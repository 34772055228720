import { RootStore } from "./../root-store/root-store"
import { api } from "./../../services/api"
import { Instance, SnapshotOut, types, flow, getParent } from "mobx-state-tree"
import { FlowType } from "../../rest-api/RestApi"
import { IFeedback, PageResponse } from "../../types"

const FeedbackModel = types.model("FeedbackModel", {
  id: types.identifierNumber,
  content: types.string,
  checked: types.boolean,
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

type FeedbackModel = Instance<typeof FeedbackModel>

export const FeedbackStoreModel = types
  .model("FeedbackStore")
  .props({
    fetching: types.optional(types.boolean, false),
    feedbacks: types.optional(types.array(FeedbackModel), []),
    feedbacksTotal: types.optional(types.number, 0),
  })
  .actions(self => ({
    getFeedbacks: flow(function* (page: number, pageSize: number, search?: string, checked?: boolean): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const response: PageResponse<IFeedback> = yield api.feedbacks.getAll({ page, pageSize, search, checked })
      if (response) {
        self.feedbacks.replace(response.results)
        self.feedbacksTotal = response.total
        self.fetching = false
        return true
      }
      messageStore.setMessage("feedback.error", "error")
      self.fetching = false
      return false
    }),
    getFeedback: flow(function* (feedbackId): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.feedbacks.getOne(feedbackId)
      if (result) {
        self.fetching = false
        return result
      }
      messageStore.setMessage("feedback.error", "error")
      self.fetching = false
      return null
    }),
    updateFeedback: flow(function* (feedback: IFeedback): FlowType {
      const { messageStore } = getParent(self) as RootStore
      const result: boolean = yield api.feedbacks.update(feedback)
      if (result) {
        messageStore.setMessage("common.updated", "success")
        return
      }
      messageStore.setMessage("feedback.error", "error")
      return
    }),
    deleteFeedback: flow(function* (feedbackId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.feedbacks.delete(feedbackId)
      if (result) {
        messageStore.setMessage("feedback.deleted", "success")
        return true
      }
      messageStore.setMessage("feedback.error", "error")
      return null
    }),
  }))

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type FeedbackStoreType = Instance<typeof FeedbackStoreModel>
export interface FeedbackStore extends FeedbackStoreType {}
type FeedbackStoreSnapshotType = SnapshotOut<typeof FeedbackStoreModel>
export interface FeedbackStoreSnapshot extends FeedbackStoreSnapshotType {}
