import React from "react"
import { useTranslation } from "react-i18next"
import { TableCell, TableRow as MUITableRow, IconButton } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { color } from "../../theme"
import { CommonAttributes } from "../../types"

const useStyles = makeStyles({
  root: {
    backgroundColor: color.white,
    height: 50,
    cursor: "pointer",
    transition: "all 150ms",
  },
  hover: {
    "&$hover:hover": {
      backgroundColor: color.palette.grey6,
    },
  },
  cell: {
    fontSize: 16,
    fontFamily: "Muli",
    color: color.text,
    textAlign: "center",
    border: `1px solid ${color.lighterLine}`,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottomWidth: 0,
    "&:first-child": {
      borderLeftWidth: 0,
      textAlign: "left",
    },
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
  editBtn: {
    padding: 0,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  deleteButton: {
    color: color.palette.highlightColor,
  },
})

type TableRowProps<T> = {
  item: T
  toSingle?(itemId: number): void
  edit?: boolean
  properties: {
    key: keyof T
    title: string
  }[]
}

export function TableRow<T extends CommonAttributes>(props: TableRowProps<T>) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { item, toSingle, properties, edit } = props

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    if (toSingle) toSingle(item.id as number)
  }

  const renderCells = () => {
    if (item) {
      return properties.map(it => {
        const value = item[it.key]
        return (
          <TableCell key={it.key as string} className={classes.cell}>
            {it.key === "role" ? t(`users.${value}`) : value}
          </TableCell>
        )
      })
    }
    return null
  }

  const renderTableCell = () => {
    if (edit) {
      return (
        <TableCell className={classes.cell}>
          <IconButton onClick={handleClick} className={classes.editBtn} aria-label={t("common.edit")}>
            <Edit />
          </IconButton>
        </TableCell>
      )
    }
    return null
  }

  return (
    <MUITableRow classes={{ root: classes.root, hover: classes.hover }} hover>
      {renderCells()}
      {renderTableCell()}
    </MUITableRow>
  )
}

export default TableRow
