import React, { useState, useEffect } from "react"
import { observer } from "mobx-react"
import { useHistory, useParams } from "react-router-dom"
import moment from "moment"
import { INotification } from "../../types"
import { FormContainer, Screen } from "../../components"
import { NotificationForm } from "./NotificationForm"
import { useStores } from "../../models/root-store"
import { NotificationSubmit } from "./NotificationSubmit"

export const SEND_OPTIONS = [
  { label: "notification.now", value: "now" },
  { label: "notification.saveAsDraft", value: "saveAsDraft" },
  { label: "notification.specifyTime", value: "specifyTime" },
]

export type NotificationStateType = Partial<Omit<INotification, "topic"> & { topic?: string[] } & { publishedAt: any }>

export const NotificationScreen: React.FC = observer(() => {
  const { id } = useParams<{ id: string }>()
  const {
    notificationStore: { getNotification, submit, fetching },
    userStore: { isAdmin },
    restaurantStore: { restaurantsForUser },
  } = useStores()
  const history = useHistory()
  const [notificationState, setNotificationState] = useState<NotificationStateType>({ topic: [] })
  const [sendOption, setSendOption] = useState<string>(SEND_OPTIONS[0].value)

  const setTime = (time: moment.Moment) => {
    setNotificationState({
      ...notificationState,
      publishedAt: moment(notificationState.publishedAt)
        .set("hours", time.get("hours"))
        .set("minutes", time.get("minutes")),
    })
  }

  const setDate = (time: moment.Moment) => {
    setNotificationState({
      ...notificationState,
      publishedAt: moment(notificationState.publishedAt)
        .set("day", time.get("day"))
        .set("month", time.get("month"))
        .set("year", time.get("year")),
    })
  }

  const getWithId = async (id: number) => {
    const existingNotification: INotification = await getNotification(id)
    if (existingNotification) {
      if (existingNotification.publishedAt) setSendOption(SEND_OPTIONS[2].value)
      let deepLinkTo = ""
      if (existingNotification.deepLinkTo) {
        if (existingNotification.deepLinkTo.indexOf("panchovilla://") !== -1) {
          deepLinkTo = existingNotification.deepLinkTo.replace("panchovilla://", "")
        }
        if (existingNotification.deepLinkTo.indexOf("https://") !== -1) {
          deepLinkTo = existingNotification.deepLinkTo.replace("https://", "")
        }
      }
      setNotificationState({
        ...existingNotification,
        deepLinkTo,
        topic: existingNotification.topic?.split(";") ?? ["all"],
        publishedAt: moment(existingNotification.publishedAt),
      })
    }
  }

  useEffect(() => {
    // Only fetch something if the parameter looks like an ID
    if (id && !isNaN(Number(id))) {
      ;(async () => {
        await getWithId(~~id)
      })()
    }
  }, [id])

  const formatLink = () => {
    const link = notificationState.deepLinkTo
    if (!link) return
    let alteredLink = undefined
    // The link is probably a URL if it contains a period
    if (link.indexOf(".") !== -1) {
      // Prepend link with https, if absent
      if (link.indexOf("https://") === -1) alteredLink = `https://${link}`
      return alteredLink || link
    }
    // Otherwise format as deep link
    return `panchovilla://${link}`
  }

  const handleSubmit = async () => {
    const submitObject: INotification = {
      ...notificationState,
      topic: undefined,
      active: false,
    }
    if (sendOption === SEND_OPTIONS[0].value) {
      // Send now
      submitObject.publishedAt = moment().toISOString(true)
    } else if (notificationState.publishedAt) {
      // Send at specified time
      submitObject.publishedAt = moment(notificationState.publishedAt).toISOString(true)
    }
    submitObject.active = submitObject.publishedAt ? true : false
    submitObject.topic = notificationState.topic?.join(";") || "all"
    submitObject.deepLinkTo = formatLink()
    const success = await submit(submitObject as INotification)
    if (success) history.goBack()
  }

  const onFieldChange = (
    fieldId: keyof INotification | "pDate" | "pTime",
    value: string | string[] | moment.Moment | null
  ) => {
    // Setting date and time has to be handled separately because they come from different inputs
    if (fieldId === "pTime") return setTime(moment(value))
    if (fieldId === "pDate") return setDate(moment(value))
    setNotificationState({ ...notificationState, ...{ [fieldId]: value } })
  }
  const getFieldValue = (id: keyof INotification) => (notificationState && notificationState[id]) || ""

  const disabled =
    !notificationState.title || !notificationState.content || (!notificationState.topic?.length && isAdmin)

  const relatedToAdmin =
    !!notificationState.topic?.length &&
    notificationState.topic[0] !== "all" &&
    restaurantsForUser.some(it => notificationState.topic?.includes(it.name))

  const isDisabledForAdmin = isAdmin && !!notificationState.id && !relatedToAdmin

  return (
    <Screen header="notification.create" horizontal>
      <FormContainer>
        <NotificationForm
          state={notificationState}
          onFieldChange={onFieldChange}
          getFieldValue={getFieldValue}
          isDisabledForAdmin={isDisabledForAdmin}
        />
        <NotificationSubmit
          onFieldChange={onFieldChange}
          getFieldValue={getFieldValue}
          sendOption={sendOption}
          onSendOptionChange={setSendOption}
          onSubmit={handleSubmit}
          fetching={fetching}
          disabled={disabled}
          isDisabledForAdmin={isDisabledForAdmin}
        />
      </FormContainer>
    </Screen>
  )
})

export default NotificationScreen
