import { RestService } from "../RestService"
import { IQrCode } from "../../types"

const URL = "/qrcodes"

export class QrCodeApi extends RestService {
  async getOne(restaurantId: number) {
    const res = await this.httpClient.get<IQrCode>(`${URL}/${restaurantId}`)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async create(restaurantId: number) {
    const res = await this.httpClient.post(URL, { restaurantId }, this.headers.json)
    return res.ok
  }

  async getAllDownloadEventsByQrCodeId(qrCodeId: number) {
    const res = await this.httpClient.get(`${URL}/${qrCodeId}/events`, {}, this.headers.json)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }
  async createNewDownloadEvent(qrCodeId: number) {
    const res = await this.httpClient.post(`${URL}/events`, { id: qrCodeId }, this.headers.json)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }
}
