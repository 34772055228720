import { RootStore } from "./../root-store/root-store"
import { api } from "./../../services/api"
import { Instance, SnapshotOut, types, flow, getParent } from "mobx-state-tree"
import { FlowType } from "../../rest-api/RestApi"
import { IBanner, PageResponse } from "../../types"

const BannerModel = types.model("BannerModel", {
  id: types.identifierNumber,
  title: types.string,
  imageId: types.maybeNull(types.number),
  publish: types.maybeNull(types.string),
  userId: types.number,
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  sentAt: types.maybeNull(types.string),
})

type BannerModel = Instance<typeof BannerModel>

export const BannerStoreModel = types
  .model("BannerStore")
  .props({
    banner: types.maybe(types.model("Banner")),
    banners: types.optional(types.array(BannerModel), []),
    bannersTotal: types.optional(types.number, 0),
    fetching: types.optional(types.boolean, false),
  })
  .actions(self => ({
    createBanner: flow(function* (banner: IBanner): FlowType {
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.banners.create(banner)
      if (result) {
        messageStore.setMessage("Uusi banneri luotu!", "success")
        return true
      }
      messageStore.setMessage("Tapahtui virhe!", "error")
      return false
    }),
    getBanner: flow(function* (bannerId): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.banners.getOne(bannerId)
      if (result) {
        self.fetching = false
        return result
      }
      messageStore.setMessage("Tapahtui virhe!", "error")
      self.fetching = false
      return null
    }),
    getBanners: flow(function* (page: number, pageSize: number, search?: string, published?: boolean): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const response: PageResponse<IBanner> = yield api.banners.getAll({
        page,
        pageSize,
        search,
        published,
      })
      if (response) {
        self.banners.replace(response.results)
        self.bannersTotal = response.total
        self.fetching = false
        return true
      }
      messageStore.setMessage("Tapahtui virhe!", "error")
      self.fetching = false
      return false
    }),
    updateBanner: flow(function* (banner: IBanner): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.banners.update(banner)
      if (result) {
        messageStore.setMessage("common.updated", "success")
        self.fetching = false
        return true
      }
      messageStore.setMessage("Tapahtui virhe!", "error")
      self.fetching = false
      return false
    }),
    deleteBanner: flow(function* (bannerId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.banners.delete(bannerId)
      if (result) {
        messageStore.setMessage("Banneri poistettu!", "success")
        return true
      }
      messageStore.setMessage("Tapahtui virhe!", "error")
      return null
    }),
  }))
  
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type BannerStoreType = Instance<typeof BannerStoreModel>
export interface BannerStore extends BannerStoreType {}
type BannerStoreSnapshotType = SnapshotOut<typeof BannerStoreModel>
export interface BannerStoreSnapshot extends BannerStoreSnapshotType {}