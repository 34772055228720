import { RestService } from "../RestService"
import { ICouponEvent } from "../../types"

const URL = "/coupon-event"

export class CouponEventApi extends RestService {
  async getOne(couponId: number) {
    const res = await this.httpClient.get<ICouponEvent>(`${URL}/${couponId}`)
    if (res.ok) {
      return res
    } else {
      return null
    }
  }

  async create(couponId: number) {
    const res = await this.httpClient.post(`${URL}/${couponId}`, {}, this.headers.json)
    return res.ok
  }
}
