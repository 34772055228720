import { RestService } from "../RestService"
import { IFeedback, QueryParams, PageResponse } from "../../types"

const URL = "/feedback"

export class FeedbackApi extends RestService {
  async getOne(feedbackId: number) {
    const res = await this.httpClient.get<IFeedback>(`${URL}/${feedbackId}`)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async getAll(params?: QueryParams) {
    const res = await this.httpClient.get<PageResponse<IFeedback>>(URL, { ...params })
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async create(feedback: IFeedback) {
    const res = await this.httpClient.post(URL, feedback, this.headers.json)
    return res.ok
  }

  async update(feedback: IFeedback) {
    const res = await this.httpClient.put(URL, feedback, this.headers.json)
    return res.ok
  }

  async delete(id: number) {
    const url = `${URL}/${id}`
    const res = await this.httpClient.delete(url)
    return res.ok
  }
}