import { create, ApisauceInstance } from "apisauce"
import { IRegistrationParams, VersionResponse, ProviderType, RoleType } from "../types"
import {
  NotificationApi,
  UserApi,
  QrCodeApi,
  RestaurantApi,
  CouponApi,
  ImageApi,
  FeedbackApi,
  AuthApi,
  CouponEventApi,
  LunchPassApi,
  MagentoApi,
  UserSettingsApi,
  BannerApi,
} from "./services"

export const ApplicationEnvironment = {
  production: "https://pancho-api.peanutsgroup.com",
  staging: "https://pancho-api-staging.peanutsgroup.com",
  development: "http://localhost:3001",
} as const
type EnvironmentType = keyof typeof ApplicationEnvironment

export enum TokenValidResponse {
  valid,
  invalid,
  offline,
}

export type FlowType = Generator<any, any, any>

export abstract class RestApi {
  // --------- SELECT ENVIRONMENT ---------
  private token: string | null | undefined

  abstract cleanUserData(): void
  abstract saveToken(token: string): void | Promise<void>
  abstract loadToken(): Promise<string | null | undefined> | string | null | undefined

  // --------- DEFINE API SERVICES ---------
  httpClient!: ApisauceInstance
  auth!: AuthApi
  notifications!: NotificationApi
  users!: UserApi
  qrCode!: QrCodeApi
  restaurants!: RestaurantApi
  coupons!: CouponApi
  images!: ImageApi
  feedbacks!: FeedbackApi
  couponEvents!: CouponEventApi
  lunchPass!: LunchPassApi

  magento!: MagentoApi
  userSettings!: UserSettingsApi
  banners!: BannerApi

  get environment() {
    let ApiUrl: string = ApplicationEnvironment.production
    if (window.location.hostname.includes('staging.peanutsgroup.com')) {
      ApiUrl = ApplicationEnvironment.staging
    }
    if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) {
      ApiUrl = ApplicationEnvironment.development
    }
    return ApiUrl
  }

  get baseUrl() {
    return this.environment
  }
  get accessToken(): string | null | undefined {
    return this.token
  }
  get isLoggedIn(): boolean | undefined | null {
    return Boolean(this.accessToken && this.accessToken.length)
  }

  /**
   * Creates an instance of our API
   *
   * @param token Optional, can be passed if for example reinitiating API after login
   * @returns Whether API was initiated with auth header
   */
  async init(token?: string): Promise<boolean> {
    this.token = await this.loadToken()

    // If no token exists nor is provided, create API with only required services and return
    if ((!token || !token.length) && (!this.token || !this.token.length)) {
      this.httpClient = create({
        baseURL: this.environment,
      })
      this.auth = new AuthApi(this.httpClient)
      this.restaurants = new RestaurantApi(this.httpClient)
      this.coupons = new CouponApi(this.httpClient)
      this.feedbacks = new FeedbackApi(this.httpClient)
      this.magento = new MagentoApi(this.httpClient)
      this.notifications = new NotificationApi(this.httpClient)
      console.log("no token exists nor is provided")
      return false
    }

    // If we have a token, create api with auth header and services
    this.httpClient = create({
      baseURL: this.environment,
      headers: {
        authorization: (token || this.accessToken) ?? false,
      },
    })
    this.auth = new AuthApi(this.httpClient)
    this.restaurants = new RestaurantApi(this.httpClient)
    this.coupons = new CouponApi(this.httpClient)
    this.feedbacks = new FeedbackApi(this.httpClient)
    this.magento = new MagentoApi(this.httpClient)
    this.notifications = new NotificationApi(this.httpClient)

    const isTokenValid = await this.httpClient.get("/health/token")
    if (!isTokenValid.ok) {
      this.cleanUserData()
      return false
    }

    // --------- INITIALIZE API SERVICES ---------
    this.users = new UserApi(this.httpClient)
    this.qrCode = new QrCodeApi(this.httpClient)
    this.images = new ImageApi(this.httpClient)
    this.couponEvents = new CouponEventApi(this.httpClient)
    this.lunchPass = new LunchPassApi(this.httpClient)
    this.userSettings = new UserSettingsApi(this.httpClient)
    this.banners = new BannerApi(this.httpClient)
    return true
  }

  async version(): Promise<string | null> {
    const res = await this.httpClient.get<VersionResponse>("/health/version")
    if (!res.ok) {
      return null
    }
    return res.data!!.version
  }

  // When we receive a token through login or registering, save it and reinitialize our api instance
  private authCallback = async (token: string) => {
    await this.saveToken(token)
    const initialized = await this.init(token)
    if (initialized) return true
    return false
  }

  async login(email: string, password: string, validRoles?: RoleType[]) {
    return await this.auth.login(email, password, validRoles, this.authCallback)
  }

  async externalLogin(accessToken: string, provider: ProviderType) {
    return await this.auth.externalLogin(accessToken, provider, this.authCallback)
  }

  async register(registeringUser: IRegistrationParams) {
    return await this.auth.register(registeringUser, this.authCallback)
  }

  async verifyCode(code: string, email: string) {
    return await this.auth.verifyCode(code, email, this.authCallback)
  }

  async checkIfTokenIsValid(): Promise<TokenValidResponse> {
    // TODO implement rest api check to determine if token is still good
    if (this.isLoggedIn) {
      return TokenValidResponse.valid
    } else return TokenValidResponse.invalid
  }
}
