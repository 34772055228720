import { RestService } from "../RestService"
import { IUser, RoleType, QueryParams, PageResponse, LoginResponse } from "../../types"

const URL = "/users"

export class UserApi extends RestService {
  async getAll(params: QueryParams, role?: RoleType) {
    const res = await this.httpClient.get<PageResponse<IUser>>(URL, { ...params, role })
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }
  async getOne(userId: number) {
    const res = await this.httpClient.get<IUser>(`${URL}/${userId}`)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }
  async getAuthenticated() {
    const res = await this.httpClient.get<IUser>(`${URL}/authenticated-user`)
    if (res.ok) return res.data
    return null
  }
  async create(user: IUser) {
    const res = await this.httpClient.post<boolean | LoginResponse>(URL, user, this.headers.json)
    if (res.ok) return true
    return res.data as LoginResponse
  }
  async update(user: IUser) {
    const res = await this.httpClient.put(URL, user, this.headers.json)
    return res.ok
  }
  async delete(id: number) {
    const url = `${URL}/${id}`
    const res = await this.httpClient.delete(url)
    return res.ok
  }
  async deleteMobileUser() {
    const url = `${URL}/mobile-user`
    const res = await this.httpClient.delete(url)
    return res.ok
  }
}
