import { RootStore } from "./../root-store/root-store"
import { api } from "./../../services/api"
import { Instance, SnapshotOut, types, flow, getParent } from "mobx-state-tree"
import { FlowType } from "../../rest-api/RestApi"
import { ICoupon, PageResponse } from "../../types"

const CouponModel = types.model("CouponModel", {
  id: types.identifierNumber,
  title: types.string,
  imageId: types.maybeNull(types.number),
  content: types.maybeNull(types.string),
  uses: types.maybeNull(types.number),
  validFrom: types.maybeNull(types.string),
  validUntil: types.maybeNull(types.string),
  publish: types.maybeNull(types.string),
  active: types.boolean,
  userId: types.number,
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

type CouponModel = Instance<typeof CouponModel>

export const CouponStoreModel = types
  .model("CouponStore")
  .props({
    coupon: types.maybe(types.model("Coupon")),
    coupons: types.optional(types.array(CouponModel), []),
    couponsTotal: types.optional(types.number, 0),
    fetching: types.optional(types.boolean, false),
  })
  .actions(self => ({
    createCoupon: flow(function* (coupon: ICoupon): FlowType {
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.coupons.create(coupon)
      if (result) {
        messageStore.setMessage("ad.couponAdded", "success")
        return true
      }
      messageStore.setMessage("ad.error", "error")
      return false
    }),
    getCoupons: flow(function* (
      page: number,
      pageSize: number,
      search?: string,
      dateLimited?: boolean,
      published?: boolean
    ): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const response: PageResponse<ICoupon> = yield api.coupons.getAll({
        page,
        pageSize,
        search,
        dateLimited,
        published,
      })
      if (response) {
        self.coupons.replace(response.results)
        self.couponsTotal = response.total
        self.fetching = false
        return true
      }
      messageStore.setMessage("ad.error", "error")
      self.fetching = false
      return false
    }),
    getCoupon: flow(function* (couponId): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.coupons.getOne(couponId)
      if (result) {
        self.fetching = false
        return result
      }
      messageStore.setMessage("ad.error", "error")
      self.fetching = false
      return null
    }),
    updateCoupon: flow(function* (coupon: ICoupon): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.coupons.update(coupon)
      if (result) {
        messageStore.setMessage("common.updated", "success")
        self.fetching = false
        return true
      }
      messageStore.setMessage("ad.error", "error")
      self.fetching = false
      return false
    }),
    deleteCoupon: flow(function* (couponId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.coupons.delete(couponId)
      if (result) {
        messageStore.setMessage("ad.deleted", "success")
        return true
      }
      messageStore.setMessage("ad.error", "error")
      return null
    }),
  }))

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type CouponStoreType = Instance<typeof CouponStoreModel>
export interface CouponStore extends CouponStoreType {}
type CouponStoreSnapshotType = SnapshotOut<typeof CouponStoreModel>
export interface CouponStoreSnapshot extends CouponStoreSnapshotType {}
