import React, { FC, ChangeEvent, useState, useEffect } from "react"
import { useTranslation, Trans } from "react-i18next"
import { observer } from "mobx-react"
import { IconButton, InputAdornment, Tooltip } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/DeleteForever"
import { IBanner, MobileScreens } from "../types"
import moment from "moment"
import { useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import { useStores } from "../models/root-store"
import { Input, Text, SelectInput, CreateButton, Button, InputDatePicker, ModalDialog } from "./"
import { RadioButtons } from "./RadioButtons"
import { makeStyles } from "@material-ui/core"
import { color } from "../theme"
import Add from "../static/add-icon.svg"

interface BannerFormProps {
  banner: IBanner | null
}

type InUseType = "allRestaurants" | "selectedRestaurants"
type PublishType = "immediately" | "publishDate"

const useStyles = makeStyles({
  formContainer: {
    marginBottom: 30,
    maxWidth: 900,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    backgroundColor: color.white,
    border: ` 1px solid ${color.palette.borderGrey}`,
    padding: 10,
    flex: "1 1 auto",
    "@media (min-width:600px)": {
      marginBottom: 30,
      maxWidth: 900,
      display: "flex",
      flexDirection: "column",
      backgroundColor: color.white,
      border: ` 1px solid ${color.palette.borderGrey}`,
      padding: 40,
      flex: "1 1 auto",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  titleText: {
    paddingBottom: 30,
  },
  input: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Muli",
    minHeight: 50,
    marginBottom: 26,
    backgroundColor: color.palette.offWhite,
  },
  multilineInput: {
    display: "flex",
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Muli",
    minHeight: 250,
    marginBottom: 26,
    backgroundColor: color.palette.offWhite,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: 500,
    marginBottom: 20,
    marginTop: 20,
    "&:last-child": {
      marginRight: 0,
    },
  },
  line: {
    width: 15,
    height: 5,
    borderTop: `2px solid ${color.palette.black}`,
    marginRight: 10,
    marginLeft: 10,
  },
  usesInput: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Muli",
    minHeight: 50,
    marginBottom: 26,
    backgroundColor: color.palette.offWhite,
    maxWidth: 140,
    marginTop: 20,
  },
  dropdownContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  inputWrapper: {
    marginLeft: 15,
    marginTop: 20,
    width: "50%",
  },
  selectWrapper: {
    marginLeft: 15,
    marginTop: 20,
    width: "50%",
  },
  createButton: {
    width: 120,
    marginTop: 15,
  },
  editButton: {
    width: 120,
    marginLeft: 20,
    marginTop: 15,
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    marginTop: 40,
  },
  button: {
    marginRight: 20,
  },
  label: {
    textIndent: 15,
    marginTop: 20,
    marginBottom: 10,
    fontSize: 16,
    fontFamily: "Muli",
    fontWeight: 500,
    color: color.text,
  },
  emptyContainer: {
    width: 10,
    height: 10,
  },
  form: {
    display: "flex",
    flex: 1,
  },
  deleteButton: {
    padding: 0,
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  deleteIcon: {
    color: color.palette.highlightColor,
    fontSize: 28,
  },
  addImageWrapper: {
    height: 200,
    width: "100%",
    border: `2px dashed ${color.primary}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 30,
    padding: "0 50px",
  },
  uploadedFileWrapper: {
    minHeight: 200,
    minWidth: 350,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 10,
    marginBotton: 30,
  },
  createButtonContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
  },
  imageTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  image: {
    width: 280,
    height: "100%",
    minHeight: 200,
  },
  imageText: {
    marginLeft: 20,
  },
  fileText: {
    marginTop: 10,
  },
  addIcon: {
    marginRight: 10,
  },
  timePeriodTextWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  adornment: {
    height: "calc(100% + 2px)",
    color: color.palette.grey9,
    backgroundColor: color.palette.grey6,
    justifyContent: "center",
    borderRadius: 3,
    border: `1px solid ${color.line}`,
    padding: "0px 15px",
    marginLeft: -1,
    maxHeight: "none",
    fontFamily: "Muli",
    fontStyle: "italic",
  },
  addBannerText: {
    textAlign: "center",
  },
  toolTip: {
    width: 120,
    paddingTop: 20,
    marginBottom: 10,
  },
})

const DEEPLINK_OPTIONS = [
  { label: "notification.nowhere", value: "nowhere" },
  { label: "notification.screen", value: "screen" },
  { label: "notification.external", value: "external" },
]

const IN_USE_OPTIONS = [
  { label: "ad.allRestaurants", value: "allRestaurants" },
  { label: "ad.selectedRestaurants", value: "selectedRestaurants" },
]
  
const PUBLISH_OPTIONS = [
  {
    label: "ad.immediately",
    value: "immediately",
  },
  {
    label: "ad.publishDate",
    value: "publishDate",
  },
]
  
const MobileScreenOptions = Object.keys(MobileScreens).map(k => ({ label: `notification.${k}`, value: k }))

export const BannerForm: FC<BannerFormProps> = observer(({ banner }) => {
  const {
    restaurantStore: { sortedRestaurantsForUser },
    userStore: { isSuperAdmin, isAdmin, isAtLeastAdmin },
    bannerStore,
    imageStore,
  } = useStores()
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [openRemoveImageModal, setOpenRemoveImageModal] = useState(false)
  const [selectedRestaurants, setRestaurant] = useState<string[]>([])
  const [title, setTitle] = useState("")
  const [publish, setPublish] = useState<moment.Moment | null | undefined>(moment())
  const [imageId, setImageId] = useState<number | null | undefined>(null)
  const [imageContent, setImageContent] = useState<string | null>()
  const [imageName, setImageName] = useState<string>()
  const [inUseRadio, setInUseRadio] = useState<InUseType>("allRestaurants")
  const [publishRadio, setPublishRadio] = useState<PublishType>("immediately")
  const [uploadedFile, setUploadedFile] = useState<File | null>()
  const [deeplinkOption, setDeeplinkOption] = useState<string>(DEEPLINK_OPTIONS[0].value)
  const [deepLink, setDeepLink] = useState<string | null | undefined>(null)
  
  useEffect(() => {
    if (banner) {
      const { title, belongsToRestaurants, publish, imageId, belongsToImage, deepLinkTo } = banner as IBanner
      const deepLinkIsScreen = MobileScreenOptions.some(option => deepLinkTo?.includes(option.value))

      setTitle(title)
      setImageId(imageId)
      if (deepLinkIsScreen) {
        const deepLinkScreen = MobileScreenOptions.find(option => {
          return deepLinkTo?.includes(option.value)
        })?.value
        setDeepLink(deepLinkScreen)
        setDeeplinkOption(DEEPLINK_OPTIONS[1].value)
      } else if (!deepLinkIsScreen && deepLinkTo) {
        setDeepLink(deepLinkTo)
        setDeeplinkOption(DEEPLINK_OPTIONS[2].value)
      }
      setImageContent(belongsToImage?.path)
      setImageName(belongsToImage?.name)
      setRestaurant(belongsToRestaurants?.map(({ id }) => id + "") || [])
      if (belongsToRestaurants?.length) {
        setInUseRadio("selectedRestaurants")
      } else {
        setInUseRadio("allRestaurants")
      }
      if (publish) {
        setPublish(moment(publish))
        setPublishRadio("publishDate")
      } else {
        setPublishRadio("immediately")
      }
    } else if (isAdmin) {
      setInUseRadio("selectedRestaurants")
    }
  }, [banner])

  const getInUse = () =>
    isSuperAdmin && inUseRadio === "allRestaurants"
      ? null
      : selectedRestaurants.map(i => ({ restaurantId: parseInt(i, 10) }))
  
  const getPublish = () => (publishRadio === "immediately" ? null : publish?.toISOString())

  // disabled if in edit mode and radio button is selected as allRestaurants or admin restaurants are not in coupon's restaurants
  const adminHasOneBannerRestaurant = (): boolean =>
    !!banner?.belongsToRestaurants?.some(r => sortedRestaurantsForUser.find(({ id }) => id === r.id))

  const isDisabledForAdmin =
    !!banner?.id && isAdmin && (inUseRadio === "allRestaurants" || !adminHasOneBannerRestaurant())

  const isDisabled = () => {
    if (!title || isDisabledForAdmin) return true
    return inUseRadio === "selectedRestaurants" && selectedRestaurants.length === 0
  }

  const formatLink = () => {
    const link = deepLink
    if (!link) return null
    let alteredLink = undefined
    // The link is probably a URL if it contains a period
    if (link.indexOf(".") !== -1) {
      // Prepend link with https, if absent
      if (link.indexOf("https://") === -1) alteredLink = `https://${link}`
      return alteredLink || link
    }
    // Otherwise format as deep link
    return `panchovilla://${link}`
  }

  const handleSave = async () => {
    if (isDisabled()) return

    let imageIdOrNewImageId = imageId
    if (uploadedFile) {
      const image = await imageStore.createImage(uploadedFile)
      imageIdOrNewImageId = image?.id
    }

    const newBanner = {
      title,
      publish: getPublish(),
      imageId: imageIdOrNewImageId,
      restaurants: getInUse(),
    } as IBanner
    if (banner && banner.id) {
      newBanner.id = banner.id
      newBanner.deepLinkTo = formatLink()
      await bannerStore.updateBanner(newBanner as IBanner)
    } else {
      newBanner.deepLinkTo = formatLink()
      await bannerStore.createBanner(newBanner as IBanner)
    }
    history.push("/bannerin-hallinta")
  }

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => setTitle && setTitle(e.target.value)

  const handleDlTargetChange = (e: React.ChangeEvent<HTMLInputElement | any>) => {
    setDeepLink(e.target.value)
  }

  const onPublishRadioClick = (e: ChangeEvent<HTMLInputElement>) => setPublishRadio(e.target.value as PublishType)

  const openDeleteModal = () => setModalOpen(true)
  const closeDeleteModal = () => setModalOpen(false)
  const openImageModal = () => setImageModalOpen(true)
  const closeImageModal = () => setImageModalOpen(false)
  const imageRemoveModalOpen = () => setOpenRemoveImageModal(true)

  const cancelImageUpload = () => {
    setUploadedFile(undefined)
    closeImageModal()
  }
  
  const handlePublishTimeChange = (_: any, date: moment.Moment | null) => {
    if (date) {
      const newDate = date.startOf("day") // sets time to 00:00:00
      setPublish(newDate)
    } else {
      setPublish(null)
    }
  }

  const handleCancel = () => {
    history.push("/bannerin-hallinta")
  }

  const cancelRemoveImage = () => {
    setOpenRemoveImageModal(false)
  }
  
  const deleteBannerHandler = async () => {
    if (banner?.id) {
      await bannerStore.deleteBanner(banner.id)
      history.push("/bannerin-hallinta")
    }
    closeDeleteModal()
  }

  const readFile = (files: File[]) => setUploadedFile(files[0])

  const createImagePreviewURL = (file: any) => file && URL.createObjectURL(file)

  const uploadFile = async () => {
    if (uploadedFile) {
      setImageContent(createImagePreviewURL(uploadedFile))
      setImageName(uploadedFile.name)
    }
    closeImageModal()
  }
  
  const handleImageRemove = async () => {
    if (imageId) {
      setImageContent(null)
      setImageName("")
      setOpenRemoveImageModal(false)
      setImageId(null)
      setUploadedFile(null)
      try {
        await imageStore.deleteImage(imageId)
      } catch (error) {
        console.log(error)
      }
    } else {
      setImageContent(null)
      setOpenRemoveImageModal(false)
      setUploadedFile(null)
    }
  }

  const renderDelete = () => {
    if (!banner?.id || isDisabledForAdmin) return null
    return (
      <IconButton onClick={openDeleteModal} className={classes.deleteButton} aria-label="remove">
        <DeleteIcon classes={{ root: classes.deleteIcon }} />
      </IconButton>
    )
  }

  const renderImageButtons = () => {
    if (isDisabledForAdmin) return null
    if (!imageContent && isAtLeastAdmin) {
      return (
        <CreateButton className={classes.createButton} onClick={openImageModal}>
          <img className={classes.addIcon} src={Add} alt="icon" />
          <Text preset="buttonCreate" tx="ad.image" />
        </CreateButton>
      )
    }
    return (
      <CreateButton className={classes.editButton} onClick={imageRemoveModalOpen}>
        <Text preset="buttonCreate" tx="ad.remove" />
      </CreateButton>
    )
  }

  const renderPublish = () => {
    if (publishRadio === "immediately") return <div className={classes.emptyContainer} />
    const publishTime = moment(publish)
    return (
      <div className={classes.dateContainer}>
        <InputDatePicker value={publishTime} onChange={handlePublishTimeChange} disabled={isDisabledForAdmin} />
      </div>
    )
  }

  const renderAddImageModalContent = () =>
    uploadedFile ? (
      <div className={classes.uploadedFileWrapper}>
        <img className={classes.image} src={createImagePreviewURL(uploadedFile)} alt="coupon_image" />
        <div className={classes.fileText}>
          <Text preset="smallDefault" tx={uploadedFile.name} />
        </div>
      </div>
    ) : (
      <Dropzone onDrop={readFile}>
        {({ getRootProps, getInputProps }) => (
          <div>
            <div className={classes.addImageWrapper} {...getRootProps()}>
              <input {...getInputProps()} />
              <Text preset="default" tx="Raahaa kuvatiedosto tähän" />
              <Text preset="default" tx="tai" />
              <div className={classes.createButtonContainer}>
                <CreateButton>
                  <Text preset="buttonCreate" tx="ad.pickImage" />
                </CreateButton>
              </div>
              <Text preset="default" text="Lisätään sovelluksen koontinäkymään" className={classes.addBannerText} />
            </div>
          </div>
        )}
      </Dropzone>
    )

  const renderExternalAdornment = () => (
    <InputAdornment className={classes.adornment} position="start">
      {"https://"}
    </InputAdornment>
  )

  const renderDeeplinkSelection = () => {
    if (deeplinkOption === "screen") {
      return (
        <SelectInput
          className={classes.selectWrapper}
          placeholder="notification.selectScreen"
          onChange={handleDlTargetChange}
          options={MobileScreenOptions}
          value={deepLink}
          disabled={isDisabledForAdmin}
        />
      )
    }
    if (deeplinkOption === "external") {
      return (
        <Input
          className={classes.inputWrapper}
          value={deepLink}
          onChange={handleDlTargetChange}
          InputProps={{ startAdornment: renderExternalAdornment() }}
          disabled={isDisabledForAdmin}
        />
      )
    }
    return null
  }

  const handleDeeplinkRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeepLink("")
    setDeeplinkOption(e.target.value)
  }

  const getHeader = () => {
    if (!banner?.id) return "ad.createNew"
    if (banner.id && !isDisabledForAdmin) return "common.edit"
    return "common.view"
  }

  return (
    <div className={classes.formContainer}>
      <div className={classes.headerContainer}>
        <Text preset="formHeader" tx={getHeader()} />
        {renderDelete()}
      </div>
      <Text preset="formLabelBold" tx="ad.heading" />
      <Input
        className={classes.input}
        value={title}
        onChange={handleTitleChange}
        placeholder="ad.heading"
        disabled={isDisabledForAdmin}
      />
      <Text preset="formLabelBold" tx="ad.image" />
      <div className={classes.imageContainer}>
        {imageContent && <img className={classes.image} src={imageContent} alt="coupon_image" />}
        <div className={classes.imageTextContainer}>
          {imageContent && <Text className={classes.imageText} preset="default" text={imageName} />}
          {renderImageButtons()}
        </div>
      </div>
      <Text preset="formLabelBold" tx="Ohjataanko käyttäjä kuvaa painamalla?" />
      <RadioButtons
        onRadioClick={handleDeeplinkRadioClick}
        buttons={DEEPLINK_OPTIONS}
        checked={deeplinkOption}
        disabled={isDisabledForAdmin}
      />
      {renderDeeplinkSelection()}
      <div className={classes.toolTip}>
        <Tooltip title="Huom. Julkaistaan 5 minuutin sisällä jos valitset heti" placement="right" arrow>
          <div>
            <Text preset="formLabelBold" tx="Julkaistaan &#9432;" />
          </div>
        </Tooltip>
      </div>
      <RadioButtons
        onRadioClick={onPublishRadioClick}
        buttons={PUBLISH_OPTIONS}
        checked={publishRadio}
        disabled={isDisabledForAdmin}
      />
      {renderPublish()}
      <div className={classes.buttons}>
        <Button className={classes.button} onClick={handleSave} disabled={isDisabled()}>
          <Text preset="button" tx={publishRadio === "publishDate" ? "common.save" : "common.publish"} />
        </Button>
        <Button secondary onClick={handleCancel}>
          <Text preset="button" tx="common.cancel" />
        </Button>
      </div>
      <ModalDialog
        isOpen={imageModalOpen}
        title="Lisää bannerikuva"
        onLeftClick={cancelImageUpload}
        onRightClick={uploadFile}
        leftButtonText="common.cancel"
        rightButtonText="Lataa"
      >
        {renderAddImageModalContent()}
      </ModalDialog>
      <ModalDialog
        isOpen={openRemoveImageModal}
        title="Poista kuva"
        onLeftClick={handleImageRemove}
        onRightClick={cancelRemoveImage}
        leftButtonText="common.remove"
        rightButtonText="common.cancel"
        deleteButton={true}
      />
      <ModalDialog
        isOpen={modalOpen}
        title="Poista banneri!"
        onLeftClick={deleteBannerHandler}
        onRightClick={closeDeleteModal}
        leftButtonText="common.remove"
        rightButtonText="common.cancel"
        deleteButton={true}
      />
    </div>
  )
})