import { RootStore } from "./../root-store/root-store"
import { api } from "./../../services/api"
import { Instance, SnapshotOut, types, flow, getParent } from "mobx-state-tree"
import { TokenValidResponse, FlowType } from "../../rest-api/RestApi"
import { IUser, RoleType, Roles, PageResponse, LoginResponse, AuthError } from "../../types"
import { omit } from "ramda"
import { withEnvironment } from "../extensions"

const LoginStates = ["LOGGED_IN" as const, "LOGGED_OUT" as const, "IN_PROGRESS" as const]

/**
 *  A model for the status of the current user
 */
const UserModel = types.model("UsersModel", {
  id: types.identifierNumber,
  username: types.optional(types.maybeNull(types.string), ""),
  email: types.string,
  phoneNumber: types.maybeNull(types.string),
  role: types.enumeration(Object.values(Roles)),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

export type UsersModel = Instance<typeof UserModel>

export const UserStoreModel = types
  .model("UserStore")
  .props({
    state: types.enumeration("State", LoginStates),
    user: types.maybe(UserModel),
    authenticatedUser: types.maybe(UserModel),
    token: types.maybe(types.string),
    loginError: types.maybe(types.string),
    users: types.optional(types.array(UserModel), []),
    usersTotal: types.optional(types.number, 0),
    fetching: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .views(self => ({
    get isLoggedIn() {
      return self.state === "LOGGED_IN"
    },
    get isAdmin() {
      return self.authenticatedUser?.role === Roles.ADMIN
    },
    get isSuperAdmin() {
      return self.authenticatedUser?.role === Roles.SUPERADMIN
    },
    get isAtLeastAdmin() {
      return self.authenticatedUser?.role === Roles.ADMIN || self.authenticatedUser?.role === Roles.SUPERADMIN
    },
  }))
  .actions(self => ({
    getAuthenticatedUser: flow(function* (): FlowType {
      self.fetching = true
      const result = yield api.users.getAuthenticated()
      if (result && result.role !== Roles.MOBILE) {
        self.authenticatedUser = result
        return true
      }
      self.fetching = false
      return false
    }),
  }))
  .actions(self => ({
    login: flow(function* (email: string, password: string) {
      const { messageStore } = getParent(self) as RootStore
      const { api } = self.environment

      self.state = "IN_PROGRESS"
      self.fetching = true
      const result: LoginResponse = yield api.login(email, password, [
        Roles.ADMIN,
        Roles.EDITOR,
        Roles.SUPERADMIN,
        Roles.USER,
      ])
      if (result.token) {
        self.state = "LOGGED_IN"
      } else {
        messageStore.setMessage("login.error", "error")
        self.state = "LOGGED_OUT"
      }
      self.fetching = false
    }),
    logout() {
      const rootStore = getParent(self) as RootStore
      api.cleanUserData()
      self.state = "LOGGED_OUT"
      rootStore.resetState()
    },

    checkToken: flow(function* (): FlowType {
      const tokenValid: TokenValidResponse = yield api.checkIfTokenIsValid()
      if (tokenValid === TokenValidResponse.valid) {
        self.state = "LOGGED_IN"
      }
      if (tokenValid === TokenValidResponse.invalid) {
        self.state = "LOGGED_OUT"
      }
    }),
    requestResetPasswordEmail: flow(function* (email: string) {
      const { messageStore } = getParent(self) as RootStore
      self.state = "IN_PROGRESS"
      const result = yield api.auth.resetPassword(email, true)
      self.state = "LOGGED_OUT"
      if (result.ok) {
        messageStore.setMessage("reset.link_send_success", "success")
        return true
      }
      messageStore.setMessage("reset.request-error", "error")
      return false
    }),
    setPassword: flow(function* (code: string, email: string, password: string): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.state = "IN_PROGRESS"
      const result = yield api.auth.setPassword(code, email, password)
      self.state = "LOGGED_OUT"
      if (result.ok) {
        return true
      }
      messageStore.setMessage("reset.set-error", "error")
      return false
    }),
    // ravintola täytyy lisätä
    addUser: flow(function* (user: IUser): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const result: boolean | LoginResponse = yield api.users.create(user)
      if (result && typeof result === "boolean") {
        messageStore.setMessage("users.user_added", "success")
        self.fetching = false
        return true
      }
      if (typeof result === "object" && result.error === AuthError.EmailInUse) {
        messageStore.setMessage("users.emailInUse", "error")
      } else {
        messageStore.setMessage("users.create-error", "error")
      }
      self.fetching = false
      return false
    }),
    getUser: flow(function* (userId): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.users.getOne(userId)
      if (result) {
        self.fetching = false
        return result
      }
      messageStore.setMessage("common.error", "error")
      self.fetching = false
      return null
    }),
    updateUser: flow(function* (user: IUser): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.users.update(user)
      if (result) {
        messageStore.setMessage("users.user_updated", "success")
        self.fetching = false
        return true
      }
      messageStore.setMessage("users.edit-error", "error")
      self.fetching = false
      return false
    }),
    deleteUser: flow(function* (userId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.users.delete(userId)
      if (result) {
        messageStore.setMessage("users.deleted", "success")
        return true
      }
      messageStore.setMessage("users.edit-error", "error")
      return null
    }),
    getUsers: flow(function* (page: number, pageSize: number, search?: string, role?: RoleType): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const response: PageResponse<IUser> = yield api.users.getAll({ page, pageSize, search }, role)
      if (response) {
        const users = response.results.map(user => ({
          ...user,
          username: user.username || "",
        }))
        self.users.replace(users)
        self.usersTotal = response.total
        self.fetching = false
        return true
      }
      messageStore.setMessage("common.error", "error")
      self.fetching = false
      return false
    }),
  }))
  .postProcessSnapshot(omit(["fetching"]))
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  */

type UserStoreType = Instance<typeof UserStoreModel>
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>
export interface UserStoreSnapshot extends UserStoreSnapshotType {}
