import React, { useEffect, useState, useCallback, ChangeEvent } from "react"
import { observer } from "mobx-react"
import { Screen, TableProperty, Table, Tabs, Loader } from "../components"
import { makeStyles } from "@material-ui/styles"
import { RootPaths, SubPaths } from "../routers/Paths"
import { useStores } from "../models/root-store"
import { IFeedback, CommonAttributes } from "../types"
import { useHistory } from "react-router-dom"
import { SearchBar } from "../components/layout"
import CheckIcon from "@material-ui/icons/Check"
import MarkunreadIcon from "@material-ui/icons/MarkunreadOutlined"
import moment from "moment"
import { color } from "../theme"

const useStyles = makeStyles({
  container: {
    padding: "20px 50px",
    display: "flex",
    flexDirection: "column",
    maxWidth: 1400,
    width: "100%",
    flex: 1,
    msFlexDirection: "column",
  },
  tabsAndSearch: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 30,
  },
  tabs: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 30,
  },
  searchBar: {
    marginTop: 30,
  },
  checkedIcon: {
    color: "green",
    height: 30,
    width: 30,
  },
  unCheckedIcon: {
    color: color.palette.red,
    height: 30,
    width: 30,
  },
})

interface IFeedbackTable extends IFeedback {
  checkedIcon: JSX.Element
}

const properties: TableProperty<IFeedbackTable & CommonAttributes>[] = [
  { key: "content", title: "content" },
  { key: "createdAt", title: "createdAt" },
  { key: "checkedIcon", title: "checked" },
]
const tab = [{ value: "checked", label: "feedback.unChecked" }]

const FeedbacksScreen: React.ComponentType = observer(() => {
  const { container, tabsAndSearch, tabs, searchBar, checkedIcon, unCheckedIcon } = useStyles()
  const history = useHistory()
  const { feedbackStore } = useStores()
  const { feedbacks, feedbacksTotal, fetching } = feedbackStore
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState("")
  const [selectedTab, setSelectedTab] = useState("")
  const pageSize = 10

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value || ""
    setSearch(searchQuery)
    setCurrentPage(0)
  }

  const getFeedbacks = async (page = 0) => {
    const checked = selectedTab === "checked"
    await feedbackStore.getFeedbacks(currentPage, pageSize, search, checked)
  }

  const memoizedGetFeedbacks = useCallback(getFeedbacks, [currentPage, pageSize, search, selectedTab])

  useEffect(() => {
    memoizedGetFeedbacks()
  }, [memoizedGetFeedbacks, currentPage, pageSize, search, selectedTab])

  const toSingle = (id: number) => {
    history.push(`${RootPaths.feedbacks}${SubPaths.edit}/${id}`)
  }

  const onChangePage = (event: any, page: number) => {
    setCurrentPage(page)
  }
  const onRadioClick = (value: string) => {
    setSelectedTab(value)
    setCurrentPage(0)
  }

  const renderTable = () => {
    if (feedbacks && feedbacks.length) {
      const mappedFeedback = feedbacks.map(feedback => {
        const content = feedback.content.slice(0, 100) + (feedback.content.length > 100 ? " ..." : "")
        const createdAt = moment(feedback?.createdAt).clone().format("DD.MM.YYYY  HH:mm")
        const checked = feedback.checked
        return {
          ...feedback,
          createdAt,
          content,

          checkedIcon: (
            <>{checked ? <CheckIcon className={checkedIcon} /> : <MarkunreadIcon className={unCheckedIcon} />}</>
          ),
        }
      })
      return (
        <Table<IFeedbackTable & CommonAttributes>
          total={feedbacksTotal}
          page={currentPage}
          pageSize={pageSize}
          onChangePage={onChangePage}
          toSingle={toSingle}
          items={mappedFeedback}
          properties={properties}
          edit={true}
        />
      )
    }
    return null
  }

  const renderContent = () => {
    if (fetching) return <Loader />
    if (feedbacks) {
      return (
        <div className={container}>
          <div className={tabsAndSearch}>
            <div className={tabs}>
              <Tabs onRadioClick={onRadioClick} buttons={tab} selected={selectedTab} defaultChecked="" />
            </div>
            <div className={searchBar}>
              <SearchBar width="100%" search={handleSearch} placeholder="feedback.search" />
            </div>
          </div>
          {renderTable()}
        </div>
      )
    }
  }

  return <Screen header="screens.feedbacks">{renderContent()}</Screen>
})

export default FeedbacksScreen
