import { RestService } from "../RestService"
import { ICoupon, QueryParams, PageResponse } from "../../types"

const URL = "/coupons"

export class CouponApi extends RestService {
  async getAll(params: QueryParams) {
    const res = await this.httpClient.get<PageResponse<ICoupon>>(URL, params)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async getOne(couponId: number) {
    const res = await this.httpClient.get<ICoupon>(`${URL}/${couponId}`)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async getMobileCoupons(params: QueryParams) {
    const res = await this.httpClient.get<PageResponse<ICoupon>>("/mobile-coupons", params)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }

  async create(coupon: ICoupon) {
    const res = await this.httpClient.post(URL, coupon, this.headers.json)
    return res.ok
  }

  async update(coupon: ICoupon) {
    const res = await this.httpClient.put(URL, coupon, this.headers.json)
    return res.ok
  }

  async delete(id: number) {
    const url = `${URL}/${id}`
    const res = await this.httpClient.delete(url)
    return res.ok
  }
}
