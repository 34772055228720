import { RestService } from "../RestService"
import { INotification, QueryParams, PageResponse } from "../../types"

const URL = "/notifications"

export class NotificationApi extends RestService {
  async getAll(params: QueryParams) {
    const res = await this.httpClient.get<PageResponse<INotification>>(URL, params)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }
  async getOne(id: number) {
    const res = await this.httpClient.get<INotification>(`${URL}/${id}`)
    if (res.ok) {
      return res.data
    } else {
      return null
    }
  }
  async create(notification: INotification) {
    const res = await this.httpClient.post(URL, notification, this.headers.json)
    return res.ok
  }
  async update(notification: INotification) {
    const res = await this.httpClient.put(URL, notification, this.headers.json)
    return res.ok
  }
  async delete(id: number) {
    const url = `${URL}/${id}`
    const res = await this.httpClient.delete(url)
    return res.ok
  }
}
