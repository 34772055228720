import { RootStore } from "./../root-store/root-store"
import { api } from "./../../services/api"
import { Instance, SnapshotOut, types, flow, getParent, cast } from "mobx-state-tree"
import { FlowType } from "../../rest-api/RestApi"
import { INotification, PageResponse } from "../../types"

/**
 *  A model for the status of the current user
 */
const NotificationModel = types.model("NotificationModel", {
  id: types.identifierNumber,
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  publishedAt: types.maybeNull(types.string),
  sentAt: types.maybeNull(types.string),
  userId: types.number,
  active: types.boolean,
  topic: types.maybeNull(types.string),
})

type NotificationModel = Instance<typeof NotificationModel>

export const NotificationStore = types
  .model("NotificationStore")
  .props({
    notifications: types.optional(types.array(NotificationModel), []),
    total: types.optional(types.number, 0),
    fetching: false,
  })
  .actions(self => ({
    getNotifications: flow(function* (page: number, pageSize: number, search?: string): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const response: PageResponse<INotification> = yield api.notifications.getAll({ page, pageSize, search })
      self.fetching = false
      if (response) {
        self.notifications = cast(response.results)
        self.total = response.total
        return true
      }
      messageStore.setMessage("error.notifications", "error")
      return false
    }),
  }))
  .actions(self => ({
    getNotification: flow(function* (id: number): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const response: INotification | null = yield api.notifications.getOne(id)
      self.fetching = false
      if (response) return response
      messageStore.setMessage("error.notification", "error")
      return null
    }),
    submit: flow(function* (notification: INotification): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      let response: INotification | null
      if (notification.id) {
        response = yield api.notifications.update(notification)
      } else {
        response = yield api.notifications.create(notification)
      }
      self.fetching = false
      if (response) {
        messageStore.setMessage("notification.success", "success")
        return response
      }
      messageStore.setMessage("error.notification", "error")
      return false
    }),
    deleteNotification: flow(function* (id: number): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const response = yield api.notifications.delete(id)
      self.fetching = false
      if (response) {
        yield self.getNotifications(0, 1000)
        messageStore.setMessage("notification.deleteSuccessful", "success")
        return response
      }
      messageStore.setMessage("error.notification", "error")
      return false
    }),
  }))

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type NotificationStoreType = Instance<typeof NotificationStore>
export interface NotificationStore extends NotificationStoreType {}
type NotificationStorenapshotType = SnapshotOut<typeof NotificationStore>
export interface NotificationStoreSnapshot extends NotificationStorenapshotType {}
